export enum DCS {
    A3 = "Aegean",
    AFKL = "AFKL",
    AVIATOR = "Aviator",
    RC = "Atlantic",
    BT = "AirBaltic",
    BAGS = "BAGS",
    CLOUD = "CLOUD",
    EK = "Emirates",
    MS = "Egyptair",
    AY = "Finnair",
    FI = "Icelandair",
    IPORT = "IPORT",
    INK = "INK",
    KE = "Koreanair",
    LH = "Lufthansa",
    NAV = "Navitaire",
    DY = "Norwegian",
    OG = "PLAY",
    SK = "SAS",
    TP = "TAP",
    MT = "Thomascook",
    TUI = "TUI",
    TK = "Turkish",
    WIDEROE = "Widerøe",
    WGH = "WGH"
}

export const DCS_URL = {
    [DCS.A3]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/aegean-dcs/overview?project=cloud-dcs-215511",
    [DCS.AFKL]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/afkl-dcs/overview?project=cloud-dcs-215511",
    [DCS.AVIATOR]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/aviator-dcs/overview?project=cloud-dcs-215511",
    [DCS.RC]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/atlantic-dcs?project=cloud-dcs-215511",
    [DCS.BT]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/airbaltic-dcs?project=cloud-dcs-215511",
    [DCS.EK]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/emirates-dcs?project=cloud-dcs-215511",
    [DCS.MS]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/egyptair-dcs?project=cloud-dcs-215511",
    [DCS.AY]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/finnair-dcs?project=cloud-dcs-215511",
    [DCS.FI]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/icelandair-dcs?project=cloud-dcs-215511",
    [DCS.IPORT]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/iport?project=cloud-dcs-215511",
    [DCS.INK]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/ink-dcs?project=cloud-dcs-215511",
    [DCS.KE]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/koreanair-dcs?project=cloud-dcs-215511",
    [DCS.LH]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/lufthansa-group-dcs?project=cloud-dcs-215511",
    [DCS.NAV]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/navitaire-dcs?project=cloud-dcs-215511",
    [DCS.DY]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/norwegian-dcs?project=cloud-dcs-215511",
    [DCS.OG]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/play-dcs?project=cloud-dcs-215511",
    [DCS.SK]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/sas-dcs?project=cloud-dcs-215511",
    [DCS.TP]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/tap-dcs?project=cloud-dcs-215511",
    [DCS.MT]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/thomascook-dcs?project=cloud-dcs-215511",
    [DCS.TUI]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/tui-dcs?project=cloud-dcs-215511",
    [DCS.TK]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/turkish-dcs?project=cloud-dcs-215511",
    [DCS.WIDEROE]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/wideroe-dcs?project=cloud-dcs-215511",
    [DCS.WGH]: "https://console.cloud.google.com/kubernetes/deployment/europe-west3-c/dcs-cluster-1/default/wgh-dcs?project=cloud-dcs-215511"
};

export enum WEIGHT_CODE {
    afkl = "afkl",
    a3 = "a3",
    fi = "fi",
    tp = "tp",
    ib = "ib",
    hv = "hv",
    to = "to",
    w6 = "w6",
    w9 = "w9",
    w4 = "w4",
    bix = "bix",
    blx = "blx",
    h8 = "h8",
    fh = "fh",
    pwf = "pwf",
    ay = "ay",
    fr = "fr",
    rk = "rk",
    wgh = "wgh",
    wf = "wf",
    lo = "lo",
    fn = "fn",
    d = "d",
    wk = "wk",
    jtd = "jtd",
    lh = "lh",
    tk = "tk",
    ms = "ms",
    ek = "ek",
    ke = "ke",
    bt = "bt",
    lx = "lx",
    os = "os",
    dx = "dx",
    de = "de",
    sas = "sas",
    norwegian = "norwegian",
    thomasCook = "thomasCook",
    atlantic = "atlantic",
}

export enum PREFIX {
    sk = "SK",
    wf = "WF",
    kl = "KL",
    bagsW6 = "W6",
    bagsW9 = "W9",
    dk = "DK",
    dy = "DY",
    w4 = "W4",
    a3 = "A3",
    af = "AF",
    lh = "LH",
    os = "OS",
    lx = "LX",
    sn = "SN",
    ms = "MS",
    ek = "EK",
    ay = "AY",
    rc = "RC",
    rcNoTag = "RCNoTag",
    fi = "FI",
    ib = "IB",
    to = "TO",
    hv = "HV",
    wk = "WK",
    dx = "DX",
    oa = "OA",
    ke = "KE",
    bagsBIX = "bags.BIX",
    bagsBLX = "bags.BLX",
    bags8H = "bags.BLX",
    bagsFH = "bags.FH",
    bagsPWF = "bags.PWF",
    bagsAY = "bags.AY",
    bagsDE = "bags.DE",
    wgh = "Wgh",
    d = "D-",
    jtd = "JTD",
    fn = "FN",
    wghBT = "BT.wgh",
    wghBIX = "BIX.wgh",
    wghW6 = "W6.wgh",
    wghFR = "FR.wgh",
    wghRK = "RK.wgh",
}
export const DEFAULT_PREFIX = {
    [PREFIX.sk]: "117",
    [PREFIX.wf]: "701",
    [PREFIX.kl]: "074",
    [PREFIX.bagsW6]: "0",
    [PREFIX.bagsW9]: "590",
    [PREFIX.dk]: "630",
    [PREFIX.dy]: "328",
    [PREFIX.w4]: "590",
    [PREFIX.a3]: "390",
    [PREFIX.af]: "057",
    [PREFIX.lh]: "220",
    [PREFIX.os]: "257",
    [PREFIX.lx]: "724",
    [PREFIX.sn]: "082",
    [PREFIX.ms]: "077",
    [PREFIX.ek]: "176",
    [PREFIX.ay]: "105",
    [PREFIX.rc]: "767",
    [PREFIX.rcNoTag]: "9003",
    [PREFIX.fi]: "108",
    [PREFIX.ib]: "075",
    [PREFIX.to]: "270",
    [PREFIX.hv]: "979",
    [PREFIX.wk]: "000",
    [PREFIX.dx]: "242",
    [PREFIX.oa]: "050",
    [PREFIX.ke]: "180",
    [PREFIX.bagsBIX]: "0",
    [PREFIX.bagsBLX]: "0",
    [PREFIX.bags8H]: "0",
    [PREFIX.bagsFH]: "0",
    [PREFIX.bagsPWF]: "0",
    [PREFIX.bagsAY]: "0",
    [PREFIX.bagsDE]: "0",
    [PREFIX.wgh]: "790",
    [PREFIX.d]: "000",
    [PREFIX.jtd]: "000",
    [PREFIX.fn]: "033",
    [PREFIX.wghBT]: "0",
    [PREFIX.wghBIX]: "0",
    [PREFIX.wghW6]: "0",
    [PREFIX.wghFR]: "0",
    [PREFIX.wghRK]: "0",
};