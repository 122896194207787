import React from "react";
import {
    Checkbox,
    Chip, Container,
    FormControlLabel,
    Grid, IconButton,
    LinearProgress, Menu, MenuItem,
    Paper,
    TableContainer,
    TextField, Typography
} from "@mui/material";
import {VariantType, WithSnackbarProps} from "notistack";
import {
    Util,
    DMSRESTApiClient,
    IDBUser,
    ValidationHelper,
    DMSWSClient,
    IDMSNode,
    IDBUserGroup,
    IDBUserRole,
    UserPermission,
    BBCBridgeTagomatActionId, BBCBridgeBagomatActionId
} from "dms_commons";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import INewUserRequest from "../models/INewUserRequest";
import ModalDialog from "./ModalDialog";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import MaterialTable, {Column} from "@material-table/core";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import DMSUserView from "./DMSUserView";
import RoomIcon from "@mui/icons-material/Room";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import INewUserGroupRequest from "../models/INewUserGroupRequest";
import INewUserRoleRequest from "../models/INewUserRoleRequest";
import BoltIcon from "@mui/icons-material/Code";
import {Autocomplete} from "@mui/lab";
import DMSIPView from "./DMSIPView";
import {TwoFactorRequest} from "../pages/App/App";

interface IProps extends WithSnackbarProps {
    twoFactorHandler: (request: TwoFactorRequest) => void;
    cancelTwoFactorRequest: () => void;
    classes: any;
    isLoadingUsers: boolean;
    dmsUsers: IDBUser[];
    dmsUserGroups?: IDBUserGroup[];
    dmsUserRoles?: IDBUserRole[];
    onLoadUsersRequested: () => void;
    onLoadUserGroupsRequested: () => void;
    onLoadUserRolesRequested: () => void;
    currentUser?: IDBUser;
    logLine: (line: string) => void;
    displaySnackbar: (message: string, variant: VariantType) => void;
    dmsClient: DMSWSClient;
    dmsRestClient: DMSRESTApiClient;
    dmsOnlineNodes: Map<string, IDMSNode>;
}

interface IState {
    newUserRequest?: INewUserRequest;
    changeUserGroupRequest?: INewUserGroupRequest;
    changeUserRoleRequest?: INewUserRoleRequest;
    targetUserGroup?: IDBUserGroup;
    targetUserRole?: IDBUserRole;
    isCreatingUserInProgress: boolean;
    isUpdatingUserGroup: boolean;
    isUpdatingUserRole: boolean;
    deletionTargetUser?: IDBUser;
    deletionTargetUserGroup?: IDBUserGroup;
    deletionTargetUserRole?: IDBUserRole;
    isDeletingUserInProgress: boolean;
    userMenuAnchorElement?: any;
    userGroupMenuAnchorElement?: any;
    userRoleMenuAnchorElement?: any;
    dmsUsersTableColumns: Column<IDBUser>[];
    dmsUserGroupsTableColumns: Column<IDBUserGroup>[];
    dmsUserRolesTableColumns: Column<IDBUserRole>[];
    targetUser?: IDBUser;
}

export default class DMSUsersView extends React.Component<IProps, IState> {
    public state: IState = {
        isCreatingUserInProgress: false,
        isDeletingUserInProgress: false,
        isUpdatingUserGroup: false,
        isUpdatingUserRole: false,
        dmsUsersTableColumns: [],
        dmsUserGroupsTableColumns: [],
        dmsUserRolesTableColumns: []
    };


    public componentDidMount() {
        this.initDmsUsersColumns();
        this.initDmsUserGroupsColumns();
        this.initDmsUserRolesColumns();
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.dmsUsers === prevProps.dmsUsers && this.props.dmsOnlineNodes === prevProps.dmsOnlineNodes) {
            return;
        }

        this.initDmsUsersColumns();
        this.initDmsUserGroupsColumns();
        this.initDmsUserRolesColumns();
    }

    private initDmsUsersColumns = () => {
        const {dmsOnlineNodes, classes} = this.props;

        const dmsUsersTableColumns: Column<IDBUser>[] = [
            {
                title: "User",
                field: "username",
                align: "left",
                filtering: false,
                render: rowData => {
                    return <DMSUserView classes={classes} username={rowData.username ?? ""}
                                        isUserOnline={(username => {
                                            return rowData.username === "dms_server" || (dmsOnlineNodes && dmsOnlineNodes[rowData.username] !== undefined);
                                        })}/>;
                }
            },
            {
                title: "Online",
                defaultSort: "desc",
                customSort: (a, b) => {
                    return (a.username === "dms_server" || (dmsOnlineNodes && dmsOnlineNodes[a.username] !== undefined))
                        ? (b.username === "dms_server" || (dmsOnlineNodes && dmsOnlineNodes[b.username] !== undefined))
                            ? 0
                            : 1
                        : -1;
                },
                render: rowData => {
                    return <Checkbox
                        checked={rowData.username === "dms_server" || (dmsOnlineNodes && dmsOnlineNodes[rowData.username] !== undefined)}
                        color="primary"
                        disabled={true}
                    />;
                }
            },
            {
                title: "Registered Date",
                field: "registeredData",
                type: "date",
                align: "center",
                render: rowData => {
                    return <p>{Util.relativeDateTimeStringFromDBTimestamp(rowData.registeredDate)}</p>;
                },
                customSort: (a, b) => {
                    if (a.registeredDate && b.registeredDate) {
                        return (a.registeredDate as any)._seconds - (b.registeredDate as any)._seconds;
                    } else {
                        return 1;
                    }
                },
            },
            {
                title: "Last Login",
                field: "lastLogin",
                type: "date",
                align: "center",
                render: rowData => {
                    return rowData.lastLogin ? <p>{Util.relativeDateTimeStringFromDBTimestamp(rowData.lastLogin)}</p> :
                        <p>N/A</p>;
                },
                customSort: (a, b) => {
                    if (a.lastLogin && b.lastLogin) {
                        return (a.lastLogin as any)._seconds - (b.lastLogin as any)._seconds;
                    } else {
                        return 1;
                    }
                },
            },
            {
                title: "Last IP",
                field: "lastIp",
                type: "string",
                align: "left",
                render: rowData => {
                    return <DMSIPView classes={classes} ipAddress={rowData.lastIp}/>;
                }
            },
            {
                title: "Group Membership",
                field: "memberOf",
                align: "center",
                render: rowData => {
                    //return <p>{(rowData.memberOf && Array.isArray(rowData.memberOf) ? rowData.memberOf : []).join(",")}</p>

                    return <div>
                        {(rowData.memberOf && Array.isArray(rowData.memberOf) ? rowData.memberOf : []).map((x, i) => {
                            return <Chip key={i} size={"small"} variant={"filled"} icon={<SupervisorAccountIcon/>}
                                         label={x}/>;
                        })}
                    </div>;
                }
            },
            {
                title: "2FA Enabled",
                field: "twoFactorAuthEnabled",
                align: "center",
                render: rowData => {
                    return <Checkbox
                        checked={rowData.twoFactorAuthEnabled === true}
                        color="primary"
                        disabled={true}
                    />;
                }
            },
            {
                title: "Viewer Token",
                field: "hasViewerToken",
                align: "center",
                render: rowData => {
                    return <Checkbox
                        checked={rowData.hasViewerToken === true}
                        color="primary"
                        disabled={true}
                    />;
                }
            },
            {
                title: "Options",
                align: "right",
                sorting: false,
                render: rowData => {
                    return (<div>
                            <IconButton
                                style={{
                                    padding: 2
                                }}
                                onClick={(event) => {
                                    this.setState({
                                        userMenuAnchorElement: event.currentTarget,
                                        targetUser: rowData
                                    });
                                }}>
                                <MoreHorizIcon/>
                            </IconButton>
                        </div>
                    );
                }
            }
        ];

        dmsUsersTableColumns.forEach(c => {
            c.width = 1;
        });

        this.setState({dmsUsersTableColumns});
    };

    private initDmsUserGroupsColumns = () => {
        const {dmsOnlineNodes, classes} = this.props;

        const dmsUserGroupsTableColumns: Column<IDBUserGroup>[] = [
            {
                title: "Id",
                field: "id",
                width: "5%",
            },
            {
                title: "Name",
                field: "displayName",
                align: "left",
                width: "10%",
                filtering: false,
                render: rowData => {
                    return <Typography>{rowData.displayName}</Typography>;
                }
            },
            {
                title: "Root",
                field: "isRoot",
                align: "left",
                width: "1%",
                render: rowData => {
                    return <Checkbox
                        checked={rowData.isRoot === true}
                        color="primary"
                        disabled={true}
                    />;
                }
            },
            {
                title: "Locations",
                field: "locations",
                align: "left",
                render: rowData => {
                    if (rowData.isRoot) {
                        return <Chip
                            key={0}
                            style={{margin: 2}}
                            size={"small"}
                            variant={"outlined"}
                            icon={<RoomIcon/>}
                            label={"All Locations"}/>;
                    }
                    return <div>
                        {(rowData.locations && Array.isArray(rowData.locations) ? rowData.locations : []).map((x, i) => {
                            return <Chip
                                key={i}
                                style={{margin: 2}}
                                size={"small"}
                                variant={"outlined"}
                                icon={<RoomIcon/>}
                                label={x}/>;
                        })}
                    </div>;
                }
            },
            {
                title: "Role",
                field: "role",
                align: "left",
                width: "10%",
                filtering: false,
                render: rowData => {
                    return <Chip size={"small"} label={rowData?.role ? rowData.role.id : "No Role"}/>;
                }
            },
            {
                title: "Created By",
                field: "createdBy",
                width: "10%",
                align: "center",
                render: rowData => {
                    return <DMSUserView classes={classes} username={rowData.createdBy ?? ""}
                                        isUserOnline={(username => {
                                            return rowData.createdBy === "dms_server" || (dmsOnlineNodes && dmsOnlineNodes[rowData.createdBy ?? ""] !== undefined);
                                        })}/>;
                }
            },
            {
                title: "Options",
                align: "right",
                sorting: false,
                render: rowData => {
                    return (<div>
                            <IconButton
                                style={{
                                    padding: 2
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();

                                    this.setState({
                                        userGroupMenuAnchorElement: event.currentTarget,
                                        targetUserGroup: rowData
                                    });
                                }}>
                                <MoreHorizIcon/>
                            </IconButton>
                        </div>
                    );
                }
            }
        ];

        this.setState({dmsUserGroupsTableColumns});
    };

    private initDmsUserRolesColumns = () => {
        const dmsUserRolesTableColumns: Column<IDBUserRole>[] = [
            {
                title: "Id",
                field: "id",
                align: "left",
                filtering: false,
                width: "5%",
            },
            {
                title: "Permissions",
                field: "permissions",
                align: "left",
                render: rowData => {
                    //return <p>{(rowData.memberOf && Array.isArray(rowData.memberOf) ? rowData.memberOf : []).join(",")}</p>

                    if (!rowData.permissions || rowData.permissions.length < 1) {
                        return <Chip size={"small"} variant={"outlined"} label={"No Permissions"}/>;
                    }

                    return <div>
                        {(rowData.permissions && Array.isArray(rowData.permissions) ? rowData.permissions : new Array<UserPermission>()).map((x, i) => {
                            return <Chip
                                key={i}
                                size={"small"}
                                variant={"outlined"}
                                style={{margin: 2}}
                                icon={<VpnKeyIcon/>}
                                label={x}/>;
                        })}
                    </div>;
                }
            },
            {
                title: "Allowed Bridge Methods",
                field: "allowedBridgeIds",
                align: "left",
                render: rowData => {
                    if (!rowData.allowedBridgeIds || rowData.allowedBridgeIds.length < 1) {
                        return <Chip size={"small"} variant={"outlined"} label={"None"}/>;
                    }

                    return <div>
                        {(rowData.allowedBridgeIds && Array.isArray(rowData.allowedBridgeIds) ? rowData.allowedBridgeIds : new Array<string>()).map((x, i) => {
                            return <Chip
                                key={i}
                                size={"small"}
                                variant={"outlined"}
                                style={{margin: 2}}
                                icon={<BoltIcon/>}
                                label={x}/>;
                        })}
                    </div>;
                }
            },
            {
                title: "Options",
                align: "right",
                sorting: false,
                render: rowData => {
                    return (<div>
                            <IconButton
                                style={{
                                    padding: 2
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    this.setState({
                                        userRoleMenuAnchorElement: event.currentTarget,
                                        targetUserRole: rowData
                                    });
                                }}>
                                <MoreHorizIcon/>
                            </IconButton>
                        </div>
                    );
                }
            }
        ];

        this.setState({dmsUserRolesTableColumns});
    };

    public render() {
        const {dmsUsers, dmsUserGroups, dmsUserRoles, isLoadingUsers} = this.props;
        const {
            dmsUsersTableColumns,
            dmsUserGroupsTableColumns,
            dmsUserRolesTableColumns,
            targetUser,
            targetUserGroup,
            targetUserRole
        } = this.state;

        return ([
            dmsUserGroups ? <Grid item xs={12} md={12}>
                <TableContainer component={Paper}>
                    <MaterialTable
                        onRowClick={(e, rowData) => {
                            if (e?.defaultPrevented) {
                                return;
                            }

                            this.setState({
                                userGroupMenuAnchorElement: undefined,
                                changeUserGroupRequest: {
                                    mode: "modify",
                                    userGroup: rowData
                                }
                            }, () => {
                                this.validateChangeUserGroupRequest(this.state.changeUserGroupRequest);
                            });
                        }}
                        columns={dmsUserGroupsTableColumns}
                        data={dmsUserGroups}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add',
                                isFreeAction: true,
                                onClick: () => {
                                    this.setState({
                                        changeUserGroupRequest: {
                                            mode: "create"
                                        }
                                    });
                                }
                            },
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => {
                                    this.props.onLoadUserGroupsRequested();
                                }
                            },
                        ]}
                        options={{
                            draggable: false,
                            emptyRowsWhenPaging: false,
                            padding: "dense",
                            search: false,
                            headerStyle: {
                                backgroundColor: "rgb(65,65,65)",
                            },
                            idSynonym: "id"
                        }}
                        title={"User Groups"}/>
                </TableContainer>
            </Grid> : undefined,
            dmsUserRoles ? <Grid item xs={12} md={12}>
                <TableContainer component={Paper}>
                    <MaterialTable
                        columns={dmsUserRolesTableColumns}
                        onRowClick={(e, rowData) => {
                            if (e?.defaultPrevented) {
                                return;
                            }

                            this.setState({
                                userRoleMenuAnchorElement: undefined,
                                changeUserRoleRequest: {
                                    mode: "modify",
                                    userRole: rowData
                                }
                            }, () => {
                                this.validateChangeUserRoleRequest(this.state.changeUserRoleRequest);
                            });
                        }}
                        options={{
                            idSynonym: "id",
                            draggable: false,
                            emptyRowsWhenPaging: false,
                            padding: "dense",
                            search: false,
                            headerStyle: {
                                backgroundColor: "rgb(65,65,65)",
                            },
                            pageSize: 10,
                            pageSizeOptions: [10, 25, 50, 100],
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add',
                                isFreeAction: true,
                                onClick: () => {
                                    this.setState({
                                        changeUserRoleRequest: {
                                            mode: "create"
                                        }
                                    });
                                }
                            },
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => {
                                    this.props.onLoadUserRolesRequested();
                                }
                            },
                        ]}
                        data={dmsUserRoles}
                        title={"Roles"}/>
                </TableContainer>
            </Grid> : undefined,
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <MaterialTable
                        isLoading={isLoadingUsers}
                        title="Users"
                        columns={dmsUsersTableColumns}
                        data={dmsUsers}
                        options={{
                            showTitle: true,
                            draggable: false,
                            pageSize: 25,
                            pageSizeOptions: [25, 50, 100],
                            loadingType: "overlay",
                            filtering: false,
                            emptyRowsWhenPaging: false,
                            padding: "dense",
                            headerStyle: {
                                backgroundColor: "rgb(65,65,65)",
                            },
                            idSynonym: "username"
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add',
                                isFreeAction: true,
                                onClick: () => {
                                    this.setState({newUserRequest: {}});
                                }
                            },
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => {
                                    this.props.onLoadUsersRequested();
                                }
                            },
                        ]}
                        onRowClick={() => {

                        }}
                    />
                </TableContainer>
                <Grid>
                    <Menu
                        open={this.state.userMenuAnchorElement != null}
                        onClose={() => {
                            this.setState({userMenuAnchorElement: undefined});
                        }}
                        id="menu-cell"
                        anchorEl={this.state.userMenuAnchorElement}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <MenuItem
                            disabled={this.props.currentUser?.username === targetUser?.username}
                            onClick={() => {
                                this.setState({
                                    userMenuAnchorElement: undefined,
                                    deletionTargetUser: targetUser
                                });
                            }}>Delete User</MenuItem>
                    </Menu>

                    <Menu
                        open={this.state.userGroupMenuAnchorElement != null}
                        onClose={() => {
                            this.setState({userGroupMenuAnchorElement: undefined});
                        }}
                        id="menu-cell"
                        anchorEl={this.state.userGroupMenuAnchorElement}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <MenuItem
                            disabled={this.state.isUpdatingUserGroup}
                            onClick={() => {
                                this.setState({
                                    userGroupMenuAnchorElement: undefined,
                                    changeUserGroupRequest: {
                                        mode: "modify",
                                        userGroup: targetUserGroup
                                    }
                                }, () => {
                                    this.validateChangeUserGroupRequest(this.state.changeUserGroupRequest);
                                });
                            }}>Edit</MenuItem>
                        <MenuItem
                            disabled={this.state.isUpdatingUserGroup}
                            onClick={() => {
                                this.setState({
                                    userGroupMenuAnchorElement: undefined,
                                    deletionTargetUserGroup: targetUserGroup
                                });
                            }}>Delete</MenuItem>
                    </Menu>

                    <Menu
                        open={this.state.userRoleMenuAnchorElement != null}
                        onClose={() => {
                            this.setState({userRoleMenuAnchorElement: undefined});
                        }}
                        id="menu-cell"
                        anchorEl={this.state.userRoleMenuAnchorElement}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <MenuItem
                            disabled={this.state.isUpdatingUserRole}
                            onClick={() => {
                                this.setState({
                                    userRoleMenuAnchorElement: undefined,
                                    changeUserRoleRequest: {
                                        mode: "modify",
                                        userRole: targetUserRole
                                    }
                                }, () => {
                                    this.validateChangeUserRoleRequest(this.state.changeUserRoleRequest);
                                });
                            }}>Edit</MenuItem>
                        <MenuItem
                            disabled={this.state.isUpdatingUserRole}
                            onClick={() => {
                                this.setState({
                                    userRoleMenuAnchorElement: undefined,
                                    deletionTargetUserRole: targetUserRole
                                });
                            }}>Delete</MenuItem>
                    </Menu>

                    {this.renderNewUserDialog()}
                    {this.renderDeleteUserDialog()}
                    {this.renderDeleteUserGroupDialog()}
                    {this.renderDeleteUserRoleDialog()}
                    {this.renderChangeUserGroupDialog()}
                    {this.renderChangeUserRoleDialog()}
                </Grid>
            </Grid>]);
    }


    private validateNewUserRequest = Util.debounce((newUserRequest: INewUserRequest) => {
        let isValid: boolean;
        let passwordWeakness: number | undefined;

        const req = newUserRequest;

        if (!req) {
            isValid = false;
        } else {
            if (req.viewerToken) {
                isValid = ValidationHelper.validateUser(req.username, req.password, undefined, req.viewerToken);
            } else {
                const v = ValidationHelper.validateUser(req.username, req.password, req.memberOf);

                isValid = req.password === req.passwordConfirmation && v;

                const pwValidationResult = ValidationHelper.validatePassword(req.password!);

                if (isValid) {
                    isValid = pwValidationResult.valid;
                }
                passwordWeakness = pwValidationResult.weakness;
            }
        }

        this.setState(prevState => ({
            newUserRequest: {
                ...prevState.newUserRequest,
                validates: isValid,
                passwordWeakness
            }
        }));
    }, 500);

    private validateChangeUserGroupRequest = Util.debounce((newUserGroupRequest: INewUserGroupRequest) => {
        let isValid: boolean;

        const req = newUserGroupRequest;

        if (!req || !req.userGroup) {
            isValid = false;
        } else {
            isValid = req.userGroup!.id?.length > 3
                && req.userGroup!.id?.length < 32
                && req.userGroup!.displayName?.length > 3
                && req.userGroup!.displayName?.length < 32;
        }

        this.setState(prevState => ({
            changeUserGroupRequest: {
                ...prevState.changeUserGroupRequest!,
                validates: isValid
            }
        }));
    }, 500);

    private validateChangeUserRoleRequest = Util.debounce((newUserRoleRequest: INewUserRoleRequest) => {
        let isValid: boolean;

        const req = newUserRoleRequest;

        if (!req || !req.userRole) {
            isValid = false;
        } else {
            isValid = req.userRole!.id?.length > 3
                && req.userRole!.id?.length < 32;
        }

        this.setState(prevState => ({
            changeUserRoleRequest: {
                ...prevState.changeUserRoleRequest!,
                validates: isValid
            }
        }));
    }, 500);

    private renderNewUserDialog = () => {
        const {classes} = this.props;

        const {newUserRequest, isCreatingUserInProgress} = this.state;

        return (<ModalDialog open={newUserRequest !== undefined}
                             buttonOkIsLoading={isCreatingUserInProgress}
                             buttonOkDisabled={!newUserRequest?.validates || isCreatingUserInProgress}
                             buttonCancelDisabled={isCreatingUserInProgress}
                             title={`Register a new user`}
                             buttonOkTitle={"Register"}
                             message={""}
                             onOk={() => {
                                 if (newUserRequest) {
                                     this.onCreateUserRequested(newUserRequest);
                                 }
                             }} onCancel={() => {
            this.setState({newUserRequest: undefined});
        }}>
            <Container>
                <TextField
                    variant={"standard"}
                    value={newUserRequest?.username ?? ""}
                    disabled={isCreatingUserInProgress}
                    onChange={(event) => {
                        let newValue = "";
                        if (event && event.target && event.target.value) {
                            newValue = event.target.value;
                        }

                        this.setState(prevState => ({
                            newUserRequest: {
                                ...prevState.newUserRequest,
                                username: newValue
                            }
                        }), () => {
                            this.validateNewUserRequest(this.state.newUserRequest);
                        });
                    }}
                    className={classes.dialogTextField}
                    fullWidth
                    label={"E-mail"}/>

                {
                    <div>
                        <TextField
                            variant={"standard"}
                            value={this.state.newUserRequest?.password ?? ""}
                            disabled={isCreatingUserInProgress}
                            onChange={(event) => {
                                let newValue = "";
                                if (event && event.target && event.target.value) {
                                    newValue = event.target.value;
                                }

                                this.setState(prevState => ({
                                    newUserRequest: {
                                        ...prevState.newUserRequest,
                                        password: newValue
                                    }
                                }), () => {
                                    this.validateNewUserRequest(this.state.newUserRequest);
                                });
                            }}
                            className={classes.dialogTextField}
                            fullWidth
                            autoComplete="new-password"
                            type={"password"}
                            label={"Password"}/>
                        <TextField
                            variant={"standard"}
                            value={this.state.newUserRequest?.passwordConfirmation ?? ""}
                            disabled={isCreatingUserInProgress}
                            onChange={(event) => {
                                let newValue = "";
                                if (event && event.target && event.target.value) {
                                    newValue = event.target.value;
                                }

                                this.setState(prevState => ({
                                    newUserRequest: {
                                        ...prevState.newUserRequest,
                                        passwordConfirmation: newValue
                                    }
                                }), () => {
                                    this.validateNewUserRequest(this.state.newUserRequest);
                                });
                            }}
                            className={classes.dialogTextField}
                            fullWidth
                            autoComplete="new-password"
                            type={"password"}
                            error={(newUserRequest?.passwordWeakness ?? 1) < 1}
                            helperText={(newUserRequest?.passwordWeakness ?? 1) < 1 ? "Weak password" : undefined}
                            label={"Confirm Password"}/>
                    </div>
                }

                {
                    newUserRequest?.isViewer
                        ? <TextField
                            variant={"standard"}
                            value={this.state.newUserRequest?.viewerToken ?? ""}
                            disabled={isCreatingUserInProgress}
                            onChange={(event) => {
                                let newValue = "";
                                if (event && event.target && event.target.value) {
                                    newValue = event.target.value;
                                }

                                this.setState(prevState => ({
                                    newUserRequest: {
                                        ...prevState.newUserRequest,
                                        viewerToken: newValue
                                    }
                                }), () => {
                                    this.validateNewUserRequest(this.state.newUserRequest);
                                });
                            }}
                            className={classes.dialogTextField}
                            fullWidth
                            autoComplete="one-time-code"
                            type={"text"}
                            label={"Viewer Token"}/> :
                        undefined
                }

                <Autocomplete
                    multiple
                    options={(this.props.dmsUserGroups ?? []).map(value => value.id)}
                    disabled={isCreatingUserInProgress}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({index})} />
                        ))
                    }
                    onChange={(event, value) => {
                        this.setState(prevState => ({
                            newUserRequest: {
                                ...prevState.newUserRequest,
                                memberOf: value
                            }
                        }), () => {
                            this.validateNewUserRequest(this.state.newUserRequest);
                        });
                    }}
                    renderInput={(params) => (
                        <TextField
                            variant={"standard"}
                            {...params}
                            label="Member Of"
                            placeholder="Member Of"
                        />
                    )}
                />

                <FormControlLabel
                    style={{marginTop: "8px"}}
                    control={
                        <Checkbox
                            disabled={isCreatingUserInProgress || newUserRequest?.isViewer}
                            onChange={(e) => {
                                const checked = e.target?.checked ?? false;
                                this.setState(prevState => ({
                                    newUserRequest: {
                                        ...prevState.newUserRequest,
                                        isRoot: checked,
                                    }
                                }));
                            }
                            }
                            checked={newUserRequest?.isRoot}
                            color="primary"
                        />
                    }
                    label="Has root access"
                />

                <FormControlLabel
                    style={{marginTop: "8px"}}
                    control={
                        <Checkbox
                            disabled={isCreatingUserInProgress || newUserRequest?.isRoot}
                            onChange={(e) => {
                                const checked = e.target?.checked ?? false;
                                this.setState(prevState => ({
                                    newUserRequest: {
                                        ...prevState.newUserRequest,
                                        isViewer: checked,
                                        isRoot: false,
                                    }
                                }), () => this.validateNewUserRequest(this.state.newUserRequest));
                            }
                            }
                            checked={newUserRequest?.isViewer}
                            color="primary"
                        />
                    }
                    label="Viewer User"
                />
            </Container>
        </ModalDialog>);
    };

    private renderDeleteUserDialog = () => {
        const {isLoadingUsers} = this.props;

        return (<ModalDialog open={this.state.deletionTargetUser !== undefined}
                             buttonOkDisabled={this.state.isDeletingUserInProgress}
                             buttonCancelDisabled={this.state.isDeletingUserInProgress}
                             buttonOkIsLoading={this.state.isDeletingUserInProgress}
                             title={`Are you sure you want to delete '${this.state?.deletionTargetUser?.username}'`}
                             onOk={() => {
                                 this.onDeleteUserRequested(this.state.deletionTargetUser!);
                             }} onCancel={() => {
            this.setState({deletionTargetUser: undefined});
        }}>
            {"This action is permanent and cannot be undone!"}
            <LinearProgress
                style={{transition: "all ease-in-out 0ms", opacity: isLoadingUsers ? 1 : 0, marginTop: 8}}
            />
        </ModalDialog>);
    };

    private renderDeleteUserGroupDialog = () => {
        const {isUpdatingUserRole} = this.state;

        return (<ModalDialog open={this.state.deletionTargetUserGroup !== undefined}
                             buttonOkDisabled={this.state.isUpdatingUserGroup}
                             buttonCancelDisabled={this.state.isUpdatingUserGroup}
                             buttonOkIsLoading={this.state.isUpdatingUserGroup}
                             title={`Are you sure you want to delete '${this.state?.deletionTargetUserGroup?.id}'`}
                             onOk={() => {
                                 this.onDeleteUserGroupRequested(this.state.deletionTargetUserGroup!);
                             }} onCancel={() => {
            this.setState({deletionTargetUserGroup: undefined});
        }}>
            {"This action is permanent and cannot be undone!"}
            <LinearProgress
                style={{transition: "all ease-in-out 0ms", opacity: isUpdatingUserRole ? 1 : 0, marginTop: 8}}
            />
        </ModalDialog>);
    };

    private renderDeleteUserRoleDialog = () => {
        const {isUpdatingUserRole} = this.state;

        return (<ModalDialog open={this.state.deletionTargetUserRole !== undefined}
                             buttonOkDisabled={this.state.isUpdatingUserRole}
                             buttonCancelDisabled={this.state.isUpdatingUserRole}
                             buttonOkIsLoading={this.state.isUpdatingUserRole}
                             title={`Are you sure you want to delete '${this.state?.deletionTargetUserRole?.id}'`}
                             onOk={() => {
                                 this.onDeleteUserRoleRequested(this.state.deletionTargetUserRole!);
                             }} onCancel={() => {
            this.setState({deletionTargetUserRole: undefined});
        }}>
            {"This action is permanent and cannot be undone!"}
            <LinearProgress
                style={{transition: "all ease-in-out 0ms", opacity: isUpdatingUserRole ? 1 : 0, marginTop: 8}}
            />
        </ModalDialog>);
    };

    private renderChangeUserGroupDialog = () => {
        const {classes, dmsUserRoles} = this.props;

        const {changeUserGroupRequest, isUpdatingUserGroup} = this.state;

        if (changeUserGroupRequest?.mode !== "modify" && changeUserGroupRequest?.mode !== "create") {
            return;
        }

        return (<ModalDialog
            open={changeUserGroupRequest !== undefined}
            buttonOkIsLoading={isUpdatingUserGroup}
            buttonOkDisabled={!changeUserGroupRequest?.validates || isUpdatingUserGroup}
            buttonCancelDisabled={isUpdatingUserGroup}
            title={changeUserGroupRequest?.mode === "create" ? `New user group` : changeUserGroupRequest?.mode === "modify" ? "Edit user group" : ""}
            buttonOkTitle={"Save"}
            message={""}
            onOk={() => {
                if (changeUserGroupRequest) {
                    this.onChangeUserGroupRequested(changeUserGroupRequest);
                }
            }} onCancel={() => {
            this.setState({changeUserGroupRequest: undefined});
        }}>
            <Container>
                <TextField
                    variant={"standard"}
                    value={changeUserGroupRequest?.userGroup?.id ?? ""}
                    disabled={isUpdatingUserGroup || changeUserGroupRequest.mode !== "create"}
                    onChange={(event) => {
                        let newValue = "";
                        if (event && event.target && event.target.value) {
                            newValue = event.target.value;
                        }

                        this.setState(prevState => ({
                            changeUserGroupRequest: {
                                ...prevState.changeUserGroupRequest!,
                                userGroup: {...prevState.changeUserGroupRequest!.userGroup!, id: newValue}
                            }
                        }), () => {
                            this.validateChangeUserGroupRequest(this.state.changeUserGroupRequest);
                        });
                    }}
                    className={classes.dialogTextField}
                    fullWidth
                    label={"Id"}/>
                <TextField
                    variant={"standard"}
                    value={changeUserGroupRequest?.userGroup?.displayName ?? ""}
                    disabled={isUpdatingUserGroup}
                    onChange={(event) => {
                        let newValue = "";
                        if (event && event.target && event.target.value) {
                            newValue = event.target.value;
                        }

                        this.setState(prevState => ({
                            changeUserGroupRequest: {
                                ...prevState.changeUserGroupRequest!,
                                userGroup: {
                                    ...prevState.changeUserGroupRequest!.userGroup!,
                                    displayName: newValue
                                }
                            }
                        }), () => {
                            this.validateChangeUserGroupRequest(this.state.changeUserGroupRequest);
                        });
                    }}
                    className={classes.dialogTextField}
                    fullWidth
                    label={"Name"}/>
                <FormControlLabel
                    style={{marginTop: "8px"}}
                    control={
                        <Checkbox
                            disabled={isUpdatingUserGroup}
                            onChange={(e) => {
                                const checked = e.target?.checked ?? false;
                                this.setState(prevState => ({
                                    changeUserGroupRequest: {
                                        ...prevState.changeUserGroupRequest!,
                                        userGroup: {...prevState.changeUserGroupRequest!.userGroup!, isRoot: checked}
                                    }
                                }));
                            }
                            }
                            checked={changeUserGroupRequest?.userGroup?.isRoot ?? false}
                            color="primary"
                        />
                    }
                    label="Has root access"
                />
                <Autocomplete
                    multiple
                    options={changeUserGroupRequest?.userGroup?.locations ?? []}
                    value={changeUserGroupRequest?.userGroup?.locations}
                    disabled={isUpdatingUserGroup}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({index})} />
                        ))
                    }
                    onChange={(event, value) => {
                        this.setState(prevState => ({
                            changeUserGroupRequest: {
                                ...prevState.changeUserGroupRequest!,
                                userGroup: {...prevState.changeUserGroupRequest!.userGroup!, locations: value}
                            }
                        }), () => {
                            this.validateChangeUserGroupRequest(this.state.changeUserGroupRequest);
                        });
                    }}
                    renderInput={(params) => (
                        <TextField
                            variant={"standard"}
                            {...params}
                            label="Locations"
                            placeholder="Locations"
                        />
                    )}
                />
                <Autocomplete
                    freeSolo
                    options={dmsUserRoles ?? []}
                    value={changeUserGroupRequest.userGroup?.role}
                    disabled={isUpdatingUserGroup}
                    getOptionLabel={option => {
                        if (typeof option === "string") {
                            return option;
                        }

                        return option.id;
                    }}
                    onChange={(event, value) => {
                        // @ts-ignore
                        this.setState(prevState => ({
                            changeUserGroupRequest: {
                                ...prevState.changeUserGroupRequest!,
                                userGroup: {
                                    ...prevState.changeUserGroupRequest!.userGroup!,
                                    role: (typeof value === "string") ? value : value ? value.id : undefined
                                }
                            }
                        }), () => {
                            this.validateChangeUserGroupRequest(this.state.changeUserGroupRequest);
                        });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Role"
                            variant={"standard"}
                            placeholder="Role"
                        />
                    )}
                />
            </Container>
        </ModalDialog>);
    };

    private renderChangeUserRoleDialog = () => {
        const {classes} = this.props;

        const {changeUserRoleRequest, isUpdatingUserRole} = this.state;

        if (changeUserRoleRequest?.mode !== "modify" && changeUserRoleRequest?.mode !== "create") {
            return;
        }

        return (<ModalDialog open={changeUserRoleRequest !== undefined}
                             buttonOkIsLoading={isUpdatingUserRole}
                             buttonOkDisabled={!changeUserRoleRequest?.validates || isUpdatingUserRole}
                             buttonCancelDisabled={isUpdatingUserRole}
                             title={changeUserRoleRequest?.mode === "create" ? `New user role` : changeUserRoleRequest?.mode === "modify" ? "Edit user role" : ""}
                             buttonOkTitle={"Save"}
                             message={""}
                             onOk={() => {
                                 if (changeUserRoleRequest) {
                                     this.onChangeUserRoleRequested(changeUserRoleRequest);
                                 }
                             }} onCancel={() => {
            this.setState({changeUserRoleRequest: undefined});
        }}>
            <Container>
                <TextField
                    variant={"standard"}
                    value={changeUserRoleRequest?.userRole?.id ?? ""}
                    disabled={isUpdatingUserRole || changeUserRoleRequest.mode !== "create"}
                    onChange={(event) => {
                        let newValue = "";
                        if (event && event.target && event.target.value) {
                            newValue = event.target.value;
                        }

                        this.setState(prevState => ({
                            changeUserRoleRequest: {
                                ...prevState.changeUserRoleRequest!,
                                userRole: {...prevState.changeUserRoleRequest!.userRole!, id: newValue}
                            }
                        }), () => {
                            this.validateChangeUserRoleRequest(this.state.changeUserRoleRequest);
                        });
                    }}
                    className={classes.dialogTextField}
                    fullWidth
                    label={"Id"}/>
                <Autocomplete
                    multiple
                    options={Object.values(UserPermission)}
                    value={changeUserRoleRequest?.userRole?.permissions ?? []}
                    disabled={isUpdatingUserRole}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({index})} />
                        ))
                    }
                    onChange={(event, value) => {
                        // @ts-ignore
                        this.setState(prevState => ({
                            changeUserRoleRequest: {
                                ...prevState.changeUserRoleRequest!,
                                userRole: {...prevState.changeUserRoleRequest!.userRole!, permissions: value}
                            }
                        }), () => {
                            this.validateChangeUserRoleRequest(this.state.changeUserRoleRequest);
                        });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant={"standard"}
                            label="Permissions"
                            placeholder="Permissions"
                        />
                    )}
                />
                <Autocomplete
                    multiple
                    options={[...Object.values(BBCBridgeTagomatActionId), ...Object.values(BBCBridgeBagomatActionId)]}
                    value={changeUserRoleRequest?.userRole?.allowedBridgeIds ?? []}
                    disabled={isUpdatingUserRole}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({index})} />
                        ))
                    }
                    onChange={(event, value) => {
                        // @ts-ignore
                        this.setState(prevState => ({
                            changeUserRoleRequest: {
                                ...prevState.changeUserRoleRequest!,
                                userRole: {
                                    ...prevState.changeUserRoleRequest!.userRole!,
                                    allowedBridgeIds: value
                                }
                            }
                        }), () => {
                            this.validateChangeUserRoleRequest(this.state.changeUserRoleRequest);
                        });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant={"standard"}
                            label="Allowed Bridge Methods"
                            placeholder="Allowed Bridge Methods"
                        />
                    )}
                />
            </Container>
        </ModalDialog>);
    };

    private onCreateUserRequested = async (newUserRequest: INewUserRequest) => {
        if (!newUserRequest?.validates) {
            return;
        }

        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        this.setState({isCreatingUserInProgress: true});

        try {
            const memberOf = newUserRequest.memberOf ?? [];

            if (newUserRequest.isRoot) {
                memberOf.push("root");
            }

            let twoFactorToken = "";

            try {
                twoFactorToken = await this.getTwoFactorTokenPromise("Creating a user requires two-factor verification");
            } catch (e) {
                this.setState({
                    isCreatingUserInProgress: false
                });
                return;
            }

            this.props.cancelTwoFactorRequest();

            await this.props.dmsRestClient.registerUser(jwtToken, twoFactorToken, newUserRequest.username!, newUserRequest.password!, true, memberOf, newUserRequest.viewerToken);
            await this.props.onLoadUsersRequested();

            this.setState({isCreatingUserInProgress: false, newUserRequest: undefined});

            this.props.displaySnackbar(`Registered user ${newUserRequest.username}`, "info");
        } catch (error: any) {
            let errorText: string;

            if (error.response) {
                errorText = `${error.response.status}:${error.response.statusText}`;
            } else {
                errorText = error.toString();
            }

            this.props.cancelTwoFactorRequest();

            this.setState({isCreatingUserInProgress: false, newUserRequest: undefined});
            this.props.displaySnackbar(`Could not register user: ${newUserRequest.username}: ${errorText}`, "error");
        }
    };

    private onChangeUserGroupRequested = async (req: INewUserGroupRequest) => {
        if (!req?.validates || !req.userGroup) {
            return;
        }

        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        this.setState({isUpdatingUserGroup: true});

        const userGroup = {...req.userGroup};

        if (userGroup["tableData"]) {
            delete userGroup["tableData"];
        }

        try {
            userGroup.createdBy = this.props.currentUser?.username;

            if (req.mode === "create") {
                await this.props.dmsRestClient.saveUserGroup(jwtToken, userGroup);
            } else if (req.mode === "modify") {
                await this.props.dmsRestClient.updateUserGroup(jwtToken, userGroup);
            } else {
                throw new Error("unhandled operation");
            }

            await this.props.onLoadUserGroupsRequested();

            this.setState({isUpdatingUserGroup: false, changeUserGroupRequest: undefined});

            if (req.mode === "create") {
                this.props.displaySnackbar(`Created user group ${req.userGroup.id}`, "info");
            } else if (req.mode === "modify") {
                this.props.displaySnackbar(`Modified user group ${req.userGroup.id}`, "info");
            }
        } catch (error: any) {
            let errorText: string;

            if (error.response) {
                errorText = `${error.response.status}:${error.response.statusText}`;
            } else {
                errorText = error.toString();
            }

            this.setState({isUpdatingUserGroup: false, changeUserGroupRequest: undefined});
            this.props.displaySnackbar(`Could not modify user group: ${req.userGroup.id}: ${errorText}`, "error");
        }
    };

    private onChangeUserRoleRequested = async (req: INewUserRoleRequest) => {
        if (!req?.validates || !req.userRole) {
            return;
        }

        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        this.setState({isUpdatingUserRole: true});

        const userRole = {...req.userRole};

        if (userRole["tableData"]) {
            delete userRole["tableData"];
        }

        try {
            if (req.mode === "create") {
                await this.props.dmsRestClient.saveUserRole(jwtToken, userRole as IDBUserRole);
            } else if (req.mode === "modify") {
                await this.props.dmsRestClient.updateUserRole(jwtToken, userRole as IDBUserRole);
            } else {
                throw new Error("unhandled operation");
            }

            await this.props.onLoadUserRolesRequested();

            this.setState({isUpdatingUserRole: false, changeUserRoleRequest: undefined});

            if (req.mode === "create") {
                this.props.displaySnackbar(`Created user role ${userRole.id}`, "info");
            } else if (req.mode === "modify") {
                this.props.displaySnackbar(`Modified user role ${userRole.id}`, "info");
            }
        } catch (error: any) {
            let errorText: string;

            if (error.response) {
                errorText = `${error.response.status}:${error.response.statusText}`;
            } else {
                errorText = error.toString();
            }

            this.setState({isCreatingUserInProgress: false, changeUserRoleRequest: undefined});
            this.props.displaySnackbar(`Could not modify user role: ${req.userRole.id}: ${errorText}`, "error");
        }
    };

    private onDeleteUserRequested = async (user: IDBUser) => {
        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        this.setState({isDeletingUserInProgress: true});

        try {
            let twoFactorToken = "";

            try {
                twoFactorToken = await this.getTwoFactorTokenPromise("Deleting user " + user.username + " requires two-factor verification");
            } catch (e) {
                this.setState({
                    isDeletingUserInProgress: false
                });

                return;
            }

            this.props.cancelTwoFactorRequest();

            await this.props.dmsRestClient.deleteUser(jwtToken, twoFactorToken, user.username);

            await this.props.onLoadUsersRequested();

            this.setState({isDeletingUserInProgress: false, deletionTargetUser: undefined});
            this.props.displaySnackbar(`Deleted user ${user.username}`, "info");
        } catch (error) {
            this.setState({isDeletingUserInProgress: false, deletionTargetUser: undefined});
            this.props.displaySnackbar(`Could not delete user: ${user.username}: ${error}`, "error");
        }
    };

    private onDeleteUserGroupRequested = async (userGroup: IDBUserGroup) => {
        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        this.setState({isUpdatingUserGroup: true});

        try {
            await this.props.dmsRestClient.deleteUserGroup(jwtToken, userGroup.id);
            await this.props.onLoadUserGroupsRequested();

            this.setState({isUpdatingUserGroup: false, deletionTargetUserGroup: undefined});
            this.props.displaySnackbar(`Deleted user group ${userGroup.id}`, "info");
        } catch (error) {
            this.setState({isUpdatingUserGroup: false, deletionTargetUserGroup: undefined});
            this.props.displaySnackbar(`Could not delete user group: ${userGroup.id}: ${error}`, "error");
        }
    };

    private onDeleteUserRoleRequested = async (userRole: IDBUserRole) => {
        const jwtToken = LocalStorageHelper.getAuthToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        this.setState({isUpdatingUserRole: true});

        try {
            await this.props.dmsRestClient.deleteUserRole(jwtToken, userRole.id);
            await this.props.onLoadUserRolesRequested();

            this.setState({isUpdatingUserRole: false, deletionTargetUserRole: undefined});
            this.props.displaySnackbar(`Deleted user role ${userRole.id}`, "info");
        } catch (error) {
            this.setState({isUpdatingUserRole: false, deletionTargetUserRole: undefined});
            this.props.displaySnackbar(`Could not delete user role: ${userRole.id}: ${error}`, "error");
        }
    };

    private getInitials = (name: string) => {
        const names = name.split(" ");
        let initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    private getTwoFactorTokenPromise = (description?: string) => {
        return new Promise<string>((resolve, reject) => {
            this.props.twoFactorHandler({
                onGotToken: token => resolve(token),
                onCancelled: () => reject(),
                description
            });
        });
    };

}
