import React, {ReactElement} from "react";
import {
    AppBar, Backdrop,
    Box,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Fade,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel, LinearProgress,
    MenuItem,
    Paper,
    Select,
    Slide,
    Switch,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {Cancel, PendingActions, Save, Warning} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import {VariantType, WithSnackbarProps} from "notistack";
import {Masonry} from "@mui/lab";
import DMSDataRendererConverter from "../classes/DMSDataRendererConverter";
import {IDMSDataRendererSection} from "../classes/IDMSDataRendererSection";
import {IDMSDataRendererItem} from "../classes/IDMSDataRendererItem";
import DMSHealthView from "./DMSHealthView";
import Flag from "react-world-flags";
import dataMappings from "../DataMappings";

interface IProps extends WithSnackbarProps {
    classes: any;
    softwareName: string;
    configContents: string;
    onLoadingChanged: (isLoading: boolean) => void;
    isUserRoot: boolean;
}

interface IState {
    dataSections?: IDMSDataRendererSection[];
    isLoading: boolean;
    configLines: string[],
    currentLine: number;
}

export default class DMSDataRenderer extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            currentLine: 0,
            configLines: props.configContents.split("\n")
        };
    }

    private interval: NodeJS.Timeout | null = null;

    public componentDidMount() {
        const {softwareName, configContents} = this.props;

        this.props.onLoadingChanged(true);

        const dataMapping = dataMappings.get(softwareName);

        if (!dataMapping) {
            this.setState({
                isLoading: false
            });
            this.props.onLoadingChanged(false);
            return;
        }

        let dataSections = DMSDataRendererConverter.convertConfig(dataMapping, configContents);

        const lineDisplayDurationMs = 10;

        this.interval = setInterval(() => {
            this.setState((prevState: IState) => {
                if (prevState.currentLine < this.state.configLines.length - 1) {
                    return {currentLine: prevState.currentLine + 1};
                } else {
                    if (this.interval) {
                        clearInterval(this.interval);
                    }
                    return prevState;
                }
            });
        }, 0);

        const loadingTime = (lineDisplayDurationMs * this.state.configLines.length) + 150;

        setTimeout(() => {
            this.setState({
                dataSections,
                isLoading: false
            }, () => {
                this.props.onLoadingChanged(false);
            });
        }, loadingTime);
    }

    public componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    public render() {
        const {dataSections, isLoading, currentLine, configLines} = this.state;

        const numberOfDirtyFields = dataSections?.reduce((count, sec) => {
            return count + sec.items.reduce((itemCount, i) => {
                return itemCount + (i.originalValue !== i.newValue ? 1 : 0);
            }, 0);
        }, 0) ?? 0;

        return (
            <Grid style={{paddingLeft: 16, paddingTop: 16, paddingRight: 16, paddingBottom: 60}}>
                {
                    <Backdrop open={!isLoading && (dataSections === undefined || dataSections.length < 1)}>
                        <Fade in={!isLoading && (dataSections === undefined || dataSections.length < 1)} unmountOnExit>
                            <Grid style={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <Warning color={"warning"} fontSize={"large"}/>
                                <Typography variant={"h6"}
                                            align={"center"}>{`Easy Mode is not supported by software '${this.props.softwareName}'`}</Typography>
                            </Grid>
                        </Fade>
                    </Backdrop>
                }

                {<Backdrop open={isLoading}>
                    <Fade in={isLoading} unmountOnExit>
                        <Grid width={"100%"} padding={16}>
                            <Typography variant={"subtitle1"}
                                        align={"center"}>{`Analyzing ${this.props.softwareName} config`}</Typography>
                            <pre style={{textAlign: "center", maxLines: 1, height: 48, opacity: 0.75}}>
                                        {configLines[currentLine]}
                                </pre>

                            <LinearProgress variant={"determinate"}
                                            value={100 / this.state.configLines.length * currentLine}/>
                        </Grid>
                    </Fade>
                </Backdrop>}
                {(dataSections ?? []).map((item, index) => {
                    return this.renderDataRendererSection(item);
                })}
                <Slide in={numberOfDirtyFields > 0} direction={"up"}>
                    <AppBar position={"fixed"} sx={{top: "auto", bottom: 0}}>
                        <Toolbar>
                            <InfoIcon style={{opacity: 0.5, marginRight: 8}}/>
                            <Typography
                                variant={"h6"}>{`${numberOfDirtyFields} ${numberOfDirtyFields > 1 ? "changes" : "change"} detected, save now?`}</Typography>
                            <Box sx={{flexGrow: 1}}/>
                            <IconButton onClick={() => {
                                this.onCancelPendingChanges();
                            }}>
                                <Cancel/>
                            </IconButton>
                            <IconButton onClick={() => {
                                this.onSavePendingChanges();
                            }}>
                                <Save/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Slide>
            </Grid>
        );
    }


    private renderDataRendererSection = (section: IDMSDataRendererSection) => {
        return <Grid container flexDirection={"column"} key={section.title}>
            <Tooltip title={section.rawValue ? `(${section.rawValue})` : undefined}>
                <Typography variant="h6">
                    {section.title}
                </Typography>
            </Tooltip>

            <Masonry
                spacing={2} columns={{xs: 1, md: section.maxColumns ?? 1}}>
                {
                    section.items.map(item => {
                        return this.renderDataRendererItem(section, item);
                    })
                }
            </Masonry>
        </Grid>;
    };


    private renderDataRendererItem = (section: IDMSDataRendererSection, item: IDMSDataRendererItem<any>) => {
        const builtInPreviewInfo = this.renderBuiltInPreview(section, item);
        let previewComponent = builtInPreviewInfo?.component;

        return <Grid item style={{position: "relative"}} key={item.id}>
            <Card>
                {builtInPreviewInfo?.options?.hideTitle
                    ? <CardContent>
                        {previewComponent}
                    </CardContent>
                    : <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <CardHeader titleTypographyProps={{width: 120, textAlign: "center"}}
                                    subheaderTypographyProps={{textAlign: "center"}}
                                    avatar={previewComponent} title={item.title} subheader={item.airlineCode}/>
                        <CardContent sx={{
                            display: 'flex',
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            {(item.metadata.options?.length ?? 0) > 0 && item.metadata.options!.map((option, index) => (
                                option.title && (
                                    this.props.isUserRoot ? (
                                        <a key={index} href={option.value} target="_blank" rel="noopener noreferrer">
                                            <Chip
                                                size={"small"}
                                                sx={{ml: index > 0 ? 2 : 0}}
                                                label={option.title}
                                                icon={option.icon}/>
                                        </a>
                                    ) : (
                                        <Chip
                                            size={"small"}
                                            key={index}
                                            sx={{ml: index > 0 ? 2 : 0}}
                                            icon={option.icon}
                                            label={option.title}/>
                                    )
                                )
                            ))}
                        </CardContent>
                    </Box>
                }
                <Fade
                    unmountOnExit
                    style={{position: "absolute", bottom: -8, right: -16, zIndex: 100}}
                    in={item.newValue !== item.originalValue}>
                    <Paper variant={"outlined"} style={{borderRadius: "50%"}}>
                        <Tooltip title={`Click to reset to default (${item.originalValue})`}>
                            <IconButton
                                size={"small"}
                                onClick={() => {
                                    item.newValue = item.originalValue;
                                    this.forceUpdate();
                                }}>
                                <PendingActions/>
                            </IconButton>

                        </Tooltip>
                    </Paper>
                </Fade>
            </Card>
        </Grid>;
    };

    private renderBuiltInPreview = (section: IDMSDataRendererSection, item: IDMSDataRendererItem<any>): undefined | {
        component: ReactElement,
        options?: {
            hideTitle?: boolean
        }
    } => {
        const type = item.metadata.type;

        let component: ReactElement | undefined;
        let hideTitle: boolean = false;

        switch (type) {
            case "switch":
                component = <FormControl
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: -4,
                        padding: 0
                    }}
                    fullWidth>
                    <FormControlLabel
                        control={<Switch
                            checked={item.newValue}
                            onChange={(ev) => {
                                if (item.metadata.readonly) {
                                    return;
                                }

                                item.newValue = ev.target?.checked ?? false;
                                this.forceUpdate();
                            }}
                        />}
                        label={<Typography
                            variant={"caption"}
                            color={"rgba(255,255,255,0.7)"}
                            fontSize={11}>{item.title}</Typography>}
                        labelPlacement="top"
                    />
                </FormControl>;
                hideTitle = true;
                break;
            case "input":
                component = <TextField
                    disabled={item.metadata.readonly}
                    placeholder={item.title}
                    value={item.newValue}
                    label={item.title}
                    onChange={event => {
                        let newValue = "";
                        if (event && event.target && event.target.value) {
                            newValue = event.target.value;
                        }

                        item.newValue = newValue;
                        this.forceUpdate();
                    }}
                />;
                hideTitle = true;
                break;
            case "list":
                component = <FormControl
                    fullWidth>
                    <InputLabel>{item.title}</InputLabel>
                    <Select
                        value={item.newValue}
                        onChange={(ev) => {
                            item.newValue = ev.target.value;
                            this.forceUpdate();
                        }}>
                        {
                            item.metadata?.options?.map(option => {
                                return <MenuItem key={option.title} value={option.value}>{option.title}</MenuItem>;
                            })
                        }
                    </Select>
                </FormControl>;
                hideTitle = true;
                break;
            case "language":
                component = <Flag
                    code={DMSHealthView.getLocaleFlag(item.originalValue)}
                    height={32}
                />;
                break;
            case "airline_logo":
                const fallbackLogo = `airlines/BBC.png`;

                component = <img
                    src={"/airlines/" + item.originalValue + ".png"}
                    alt={"airline"}
                    style={{
                        height: 32
                    }}
                    onError={({currentTarget}) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = fallbackLogo;
                    }}/>;
                break;
        }

        return component ? {
            component,
            options: {
                hideTitle
            }
        } : undefined;
    };

    private displaySnackbar = (message: string, variant: VariantType = "info") => {
        this.props.enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {vertical: "top", horizontal: "center"}
        });
    };

    private onCancelPendingChanges = () => {
        const {dataSections} = this.state;

        dataSections?.forEach(s => {
            s.items.forEach(i => {
                i.newValue = i.originalValue;
            });
        });

        this.forceUpdate();
        this.displaySnackbar("Discarded all pending changes", "info");
    };

    private onSavePendingChanges = () => {
        const {dataSections} = this.state;

        dataSections?.forEach(s => {
            s.items.forEach(i => {
                i.originalValue = i.newValue;
            });
        });

        this.forceUpdate();
        this.displaySnackbar("TODO: Save pending changes", "info");
    };
}