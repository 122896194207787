import {IVersion} from "dms_commons";

export type ENV_TYPE = "test" | "prod" | "localhost_http" | "localhost_https"

export default class Constants {
    public static readonly clientTitle = "DMS Admin ";
    public static readonly dmsClientName = "dms_admin";
    public static readonly clientVersion: IVersion = {major: 1, minor: 9, build: 2};

    public static readonly env: ENV_TYPE = getEnv();
}

function getEnv(): ENV_TYPE {
    const reactAppEnv = process?.env.REACT_APP_ENV;

    switch (reactAppEnv) {
        case "development":
            return "prod";
        case "production":
            return "prod";
        case "test":
            return "test";
        default:
            return "prod";
    }
}
