import React from "react";
import {
    Card, CardContent, CardHeader,
    Chip,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    LinearProgress,
    Menu,
    MenuItem,
    Paper,
    Select,
    Switch,
    TableContainer,
    TextField, Typography
} from "@mui/material";
import {VariantType, WithSnackbarProps} from "notistack";
import {
    ABDState,
    DMSMessageFactory,
    DMSMethod,
    DMSRESTApiClient,
    DMSUpdateStrategy,
    DMSWSClient,
    IDBNode,
    IDMSIPBanInformation,
    IDMSNode,
    IDMSNodeBanState,
    IDMSResult,
    IDMSSettings,
    IDMSSoftwareBundle,
    TGMState,
    Util
} from "dms_commons";
import MaterialTable, {Column} from "@material-table/core";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ModalDialog from "./ModalDialog";
import DMSUserView from "./DMSUserView";
import IDBNotificationSubscription from "dms_commons/build/interfaces/IDBNotificationSubscription";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import Autocomplete from "@mui/material/Autocomplete";

interface IProps extends WithSnackbarProps {
    classes: any;
    onLoadSoftwareRequested: () => void;
    onLoadNotificationSettingsRequested: () => void;
    isLoadingDefinedSoftware: boolean;
    isLoadingNotificationSettings: boolean;
    definedSoftware: IDMSSoftwareBundle[],
    notificationSettings: IDBNotificationSubscription[],
    onLoadBannedNodesRequested: () => void;
    onLoadGlobalSettingsRequested: () => void;
    isLoadingBannedNodes: boolean;
    bannedNodes: IDMSNodeBanState[],
    globalSettings?: IDMSSettings;
    logLine: (line: string) => void;
    displaySnackbar: (message: string, variant: VariantType) => void;
    dmsClient?: DMSWSClient;
    dmsRestClient: DMSRESTApiClient;
    dmsNodes: Map<string, IDBNode>;
    dmsOnlineNodes: Map<string, IDMSNode>;
}

interface INewSoftwareRequest {
    name?: string;
    workingDirectory?: string;
    updateStrategy?: DMSUpdateStrategy;
    validates?: boolean;
}

interface INotificationSettingTemplate {
    state?: ABDState | TGMState;
    stateContainsFilter?: string;
}

interface INewNotificationSettingRequest {
    subscription?: IDBNotificationSubscription;
    validates?: boolean;
    template?: INotificationSettingTemplate;
    software?: string;
}

interface IBanIPRequest {
    ip?: string;
    validates?: boolean;
}

interface IState {
    softwareMenuAnchorElement?: any;
    notificationSettingMenuAnchorElement?: any;
    bannedNodesMenuAnchorElement?: any;
    bannedIpsMenuAnchorElement?: any;

    targetSoftware?: IDMSSoftwareBundle;
    targetNotificationSetting?: IDBNotificationSubscription;
    targetBannedNode?: IDMSNodeBanState;
    targetBannedIp?: IDMSIPBanInformation;

    notificationColumns: Column<IDBNotificationSubscription>[];
    softwareTableColumns: Column<IDMSSoftwareBundle>[];
    bannedNodesColumns: Column<IDMSNodeBanState>[];
    bannedIpsColumns: Column<IDMSIPBanInformation>[];
    deletionTargetSoftware?: IDMSSoftwareBundle;
    deletionTargetBannedIp?: string;
    isDeletingSoftwareInProgress?: boolean;
    isDeletingBannedIpInProgress?: boolean;
    isSavingSoftwareInProgress?: boolean;
    isSavingNotificationSettingInProgress?: boolean;
    isSavingBannedIpInProgress?: boolean;
    newSoftwareRequest?: INewSoftwareRequest;
    newNotificationSettingRequest?: INewNotificationSettingRequest;
    banIPRequest?: IBanIPRequest;
}

export default class DMSSettingsView extends React.Component<IProps, IState> {
    public state: IState = {
        softwareTableColumns: [],
        bannedNodesColumns: [],
        bannedIpsColumns: [],
        notificationColumns: []
    };

    public componentDidMount() {
        this.initColumns();
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.definedSoftware === prevProps.definedSoftware && this.props.dmsOnlineNodes === prevProps.dmsOnlineNodes) {
            return;
        }

        this.initColumns();
    }

    private notificationSubscriptionTemplates = new Map<string, INotificationSettingTemplate>([
        ["None", {}],
        ["Printer Out of Paper", {
            state: TGMState.SYSTEM_ERROR,
            stateContainsFilter: "out of paper",
        }],
        ["Paper Jam", {
            state: TGMState.SYSTEM_ERROR,
            stateContainsFilter: "Paper jammed",
        }],
        ["Paper Low", {
            state: TGMState.SYSTEM_ERROR,
            stateContainsFilter: "Paper low",
        }],
    ]);

    private initColumns = () => {
        const {classes, dmsOnlineNodes} = this.props;

        const notificationColumns: Column<IDBNotificationSubscription>[] = [
            {
                title: "State",
                field: "state",
                align: "left",
                filtering: true,
            },
            {
                title: "State Filter",
                field: "stateContainsFilter",
                align: "left",
                filtering: true,
            },
            {
                title: "Locations",
                field: "locations",
                render: (rowData: IDBNotificationSubscription) => {
                    return <Grid style={{marginLeft: -8}}>
                        {
                            rowData.locations.map((x, i) => {
                                return <Chip key={i} style={{marginLeft: 4, marginRight: 4}} label={x}/>;
                            })
                        }
                    </Grid>;
                },
                align: "center",
                filtering: true,
            },
            {
                title: "Push",
                field: "push",
                align: "center",
                filtering: false,
                render: (rowData) => {
                    return <div
                        style={{justifyContent: "center", display: "flex", alignItems: "center"}}>
                        <Switch
                            disabled={this.state.isSavingNotificationSettingInProgress}
                            onChange={async (event, checked) => {
                                const res = await this.toggleNotificationSubscription(rowData, checked, rowData.mail);
                                if (res) {
                                    rowData.push = checked;
                                    this.forceUpdate();
                                }
                            }}
                            checked={rowData.push}/>
                    </div>;
                }
            },
            {
                title: "Mail",
                field: "mail",
                align: "center",
                filtering: false,
                render: (rowData) => {
                    return <div
                        style={{justifyContent: "center", display: "flex", alignItems: "center"}}>
                        <Switch
                            disabled={this.state.isSavingNotificationSettingInProgress}
                            onChange={async (event, checked) => {
                                const res = await this.toggleNotificationSubscription(rowData, rowData.push, checked);
                                if (res) {
                                    rowData.mail = checked;
                                    this.forceUpdate();
                                }
                            }}
                            checked={rowData.mail}/>
                    </div>;
                }
            },
            {
                title: "Options",
                align: "right",
                sorting: false,
                render: rowData => {
                    return (<div>
                            <MoreHorizIcon style={{cursor: "pointer"}}
                                           onClick={(event) => {
                                               this.setState({
                                                   notificationSettingMenuAnchorElement: event.currentTarget,
                                                   targetNotificationSetting: rowData
                                               });
                                           }}>
                            </MoreHorizIcon>
                        </div>
                    );
                }
            }
        ];

        const softwareTableColumns: Column<IDMSSoftwareBundle>[] = [
            {
                title: "Name",
                field: "name",
                align: "left",
                filtering: true,
            },
            {
                title: "Directory",
                field: "workingDirectory",
                align: "left",
                filtering: true,
            },
            {
                title: "Update Strategy",
                field: "updateStrategy",
                align: "left",
                filtering: false
            },
            {
                title: "Options",
                align: "right",
                sorting: false,
                render: rowData => {
                    return (<div>
                            <MoreHorizIcon style={{cursor: "pointer"}}
                                           onClick={(event) => {
                                               this.setState({
                                                   softwareMenuAnchorElement: event.currentTarget,
                                                   targetSoftware: rowData
                                               });
                                           }}>
                            </MoreHorizIcon>
                        </div>
                    );
                }
            }
        ];

        const bannedNodesColumns: Column<IDMSNodeBanState>[] = [
            {
                title: "Node",
                field: "uid",
                align: "left",
                filtering: true,
                render: rowData => {
                    return <Chip label={rowData.uid}/>;
                }
            },
            {
                title: "Connection Attempts",
                field: "connectionAttempts",
                align: "left",
                type: "numeric",
                filtering: true,
            },
            {
                title: "Modified",
                field: "timestamp",
                render: rowData => {
                    return <p>{Util.relativeDateTimeStringFromDBTimestamp(rowData.timestamp)}</p>;
                }
            },
            {
                title: "Options",
                align: "right",
                sorting: false,
                render: rowData => {
                    return (<div>
                            <MoreHorizIcon style={{cursor: "pointer"}}
                                           onClick={(event) => {
                                               this.setState({
                                                   bannedNodesMenuAnchorElement: event.currentTarget,
                                                   targetBannedNode: rowData
                                               });
                                           }}>
                            </MoreHorizIcon>
                        </div>
                    );
                }
            }
        ];

        const bannedIpsColumns: Column<IDMSIPBanInformation>[] = [
            {
                title: "IP",
                field: "uid",
                align: "left",
                filtering: true,
                render: rowData => {
                    return <Chip label={rowData.ip}/>;
                }
            },
            {
                title: "Modified",
                field: "timestamp",
                render: rowData => {
                    return <p>{Util.relativeDateTimeStringFromDBTimestamp(rowData.created)}</p>;
                }
            },
            {
                title: "User",
                field: "createdBy",
                render: rowData => {
                    return <DMSUserView classes={classes} username={rowData.createdBy} isUserOnline={() => {
                        return rowData.createdBy === "dms_server" || (dmsOnlineNodes && dmsOnlineNodes[rowData.createdBy] !== undefined);
                    }}/>;
                }
            },
            {
                title: "Options",
                align: "right",
                sorting: false,
                render: rowData => {
                    return (<div>
                            <MoreHorizIcon style={{cursor: "pointer"}}
                                           onClick={(event) => {
                                               this.setState({
                                                   bannedIpsMenuAnchorElement: event.currentTarget,
                                                   targetBannedIp: rowData
                                               });
                                           }}>
                            </MoreHorizIcon>
                        </div>
                    );
                }
            }
        ];

        softwareTableColumns.forEach(c => {
            c.width = 1;
        });
        bannedNodesColumns.forEach(c => {
            c.width = 1;
        });
        bannedIpsColumns.forEach(c => {
            c.width = 1;
        });

        this.setState({softwareTableColumns, bannedNodesColumns, bannedIpsColumns, notificationColumns});
    };

    private toggleNotificationSubscription = async (notificationSubscription: IDBNotificationSubscription, pushEnabled: boolean, mailEnabled: boolean): Promise<boolean> => {
        try {
            this.setState({
                isSavingNotificationSettingInProgress: true
            });

            const jwtToken = LocalStorageHelper.getAuthToken();

            if (jwtToken === null) {
                console.log("missing jwt token");
                return false;
            }

            const payload = {
                ...notificationSubscription,
                push: pushEnabled,
                mail: mailEnabled
            };

            return await this.props.dmsRestClient.updateNotificationSubscription(jwtToken, payload);
        } catch (e) {
            this.props.displaySnackbar("Failed to modify notification subscription. Error: " + JSON.stringify(e), "error");
        } finally {
            this.setState({
                isSavingNotificationSettingInProgress: false
            });
        }

        return false;
    };

    private unbanNode = async (uid: string) => {
        try {
            const msg = DMSMessageFactory.newMessage(DMSMethod.UNBAN_NODE, {
                uid
            });
            await this.props.dmsClient!.sendMessage<IDMSResult<void>>(msg, true);
            this.props.onLoadBannedNodesRequested();
        } catch (e) {
            this.props.displaySnackbar("Failed to unban node " + uid + " Error: " + JSON.stringify(e), "error");
        }
    };

    private unbanIp = async (req: IDMSIPBanInformation) => {
        try {
            const msg = DMSMessageFactory.newMessage(DMSMethod.UNBAN_IP, req);
            await this.props.dmsClient!.sendMessage<IDMSResult<void>>(msg, true);
            this.props.onLoadGlobalSettingsRequested();
        } catch (e) {
            this.props.displaySnackbar("Failed to unban ip " + req.ip + " Error: " + JSON.stringify(e), "error");
        }
    };

    private renderDeleteSoftwareDialog = () => {
        const {isLoadingDefinedSoftware} = this.props;

        return (<ModalDialog
            key={"delete_software"}
            open={this.state.deletionTargetSoftware !== undefined}
            buttonOkDisabled={this.state.isDeletingSoftwareInProgress}
            buttonCancelDisabled={this.state.isDeletingSoftwareInProgress}
            buttonOkIsLoading={this.state.isDeletingSoftwareInProgress}
            title={`Are you sure you want to delete software '${this.state.deletionTargetSoftware?.name}'`}
            onOk={() => {
                this.onDeleteSoftwareRequested(this.state.deletionTargetSoftware!);
            }} onCancel={() => {
            this.setState({deletionTargetSoftware: undefined});
        }}>
            {"This action is permanent and cannot be undone!"}
            <LinearProgress
                style={{transition: "all ease-in-out 0ms", opacity: isLoadingDefinedSoftware ? 1 : 0, marginTop: 8}}
            />
        </ModalDialog>);
    };

    private onSaveSoftwareRequested = async (software: INewSoftwareRequest) => {
        this.setState({
            isSavingSoftwareInProgress: true
        });

        try {
            const msg = DMSMessageFactory.newMessage(DMSMethod.SAVE_DEFINED_SOFTWARE, {
                software
            });
            const result = await this.props.dmsClient!.sendMessage<IDMSResult<void>>(msg, true);

            if (result?.error) {
                throw result.error;
            }
        } catch (e) {
            this.props.displaySnackbar("Failed to save software Error: " + JSON.stringify(e), "error");
        } finally {
            this.setState({
                isSavingSoftwareInProgress: false,
                newSoftwareRequest: undefined
            });

            await this.props.onLoadSoftwareRequested();
        }
    };

    private onSaveNotificationSettingRequested = async (notificationSetting: INewNotificationSettingRequest) => {
        this.setState({
            isSavingNotificationSettingInProgress: true
        });

        try {
            const jwtToken = LocalStorageHelper.getAuthToken();

            if (jwtToken === null) {
                console.log("missing jwt token");
                return false;
            }

            await this.props.dmsRestClient.createNotificationSubscription(jwtToken, notificationSetting.subscription!);

            this.props.onLoadNotificationSettingsRequested();
        } catch (e) {
            this.props.displaySnackbar("Failed to save notification setting Error: " + JSON.stringify(e), "error");
        } finally {
            this.setState({
                isSavingNotificationSettingInProgress: false,
                newNotificationSettingRequest: undefined
            });

            await this.props.onLoadNotificationSettingsRequested();
        }
    };

    private validateNewSoftwareRequest = Util.debounce((newSoftwareRequest: INewSoftwareRequest) => {
        let isValid: boolean;

        const req = newSoftwareRequest;

        if (!req) {
            isValid = false;
        } else {
            isValid = req.name !== undefined && req.name.length > 0 && req.name.length < 100
                && req.workingDirectory !== undefined && req.workingDirectory.length > 0 && req.workingDirectory.length < 255
                && req.updateStrategy !== undefined;
        }

        this.setState(prevState => ({
            newSoftwareRequest: {
                ...prevState.newSoftwareRequest,
                validates: isValid
            }
        }));
    }, 500);

    private validateNewNotificationSettingRequest = Util.debounce((notificationSettingRequest: INewNotificationSettingRequest) => {
        let isValid: boolean;

        const req = notificationSettingRequest;

        if (!req) {
            isValid = false;
        } else {
            isValid = (notificationSettingRequest?.subscription &&
                notificationSettingRequest.subscription!.push !== undefined &&
                notificationSettingRequest.subscription!.mail !== undefined &&
                notificationSettingRequest.subscription!.state &&
                (notificationSettingRequest.subscription!.stateContainsFilter ? (notificationSettingRequest.subscription.stateContainsFilter.length > 0 && notificationSettingRequest.subscription.stateContainsFilter.length < 20) : true) &&
                notificationSettingRequest.subscription!.locations?.length > 0) ?? false;
        }

        this.setState(prevState => ({
            newNotificationSettingRequest: {
                ...prevState.newNotificationSettingRequest,
                validates: isValid
            }
        }));
    }, 500);

    private renderNewSoftwareDialog = () => {
        const {newSoftwareRequest, isSavingSoftwareInProgress} = this.state;

        const buttonOkDisabled = !newSoftwareRequest?.validates || isSavingSoftwareInProgress;

        return (<ModalDialog
            key={"new_software"}
            open={newSoftwareRequest !== undefined}
            buttonOkIsLoading={isSavingSoftwareInProgress}
            buttonOkDisabled={buttonOkDisabled}
            buttonCancelDisabled={isSavingSoftwareInProgress}
            title={`Add new software`}
            buttonOkTitle={"Save"}
            message={""}
            onOk={() => {
                if (newSoftwareRequest) {
                    this.onSaveSoftwareRequested(newSoftwareRequest);
                }
            }} onCancel={() => {
            this.setState({newSoftwareRequest: undefined});
        }}>
            <Container>
                <div>
                    <TextField
                        variant={"standard"}
                        value={newSoftwareRequest?.name ?? ""}
                        fullWidth
                        label={"Software Name"}
                        onChange={(event) => {
                            let newValue = "";
                            if (event && event.target && event.target.value) {
                                newValue = event.target.value.toLowerCase();
                            }

                            this.setState(prevState => ({
                                newSoftwareRequest: {
                                    ...prevState.newSoftwareRequest,
                                    name: newValue
                                }
                            }), () => this.validateNewSoftwareRequest(newSoftwareRequest));
                        }}
                        style={{margin: 8, minWidth: "120px"}}>
                    </TextField>

                    <TextField
                        variant={"standard"}
                        fullWidth
                        value={newSoftwareRequest?.workingDirectory ?? ""}
                        label={"Working Directory"}
                        onChange={(event) => {
                            let newValue = "";
                            if (event && event.target && event.target.value) {
                                newValue = event.target.value;
                            }

                            this.setState(prevState => ({
                                newSoftwareRequest: {
                                    ...prevState.newSoftwareRequest,
                                    workingDirectory: newValue
                                }
                            }), () => this.validateNewSoftwareRequest(newSoftwareRequest));
                        }}
                        style={{margin: 8, minWidth: "120px"}}>

                    </TextField>
                    <FormControl style={{margin: 8, minWidth: "120px"}} fullWidth={true}>
                        <InputLabel>Update Strategy</InputLabel>
                        <Select
                            variant={"standard"}
                            value={newSoftwareRequest?.updateStrategy ?? Object.keys(DMSUpdateStrategy)[0]}
                            onChange={(event) => {
                                let newValue = "";
                                if (event && event.target && event.target.value) {
                                    newValue = event.target.value as string;
                                }

                                if (newValue) {
                                    this.setState(prevState => ({
                                        newSoftwareRequest: {
                                            ...prevState.newSoftwareRequest,
                                            updateStrategy: newValue as DMSUpdateStrategy
                                        }
                                    }), () => this.validateNewSoftwareRequest(newSoftwareRequest));
                                }
                            }}
                        >
                            {
                                Object.keys(DMSUpdateStrategy).map((k, i) => {
                                    return <MenuItem key={i} value={DMSUpdateStrategy[k]}>{`${k}`}</MenuItem>;
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            </Container>
        </ModalDialog>);
    };

    private renderNewNotificationSettingDialog = () => {
        const {dmsNodes} = this.props;
        const {newNotificationSettingRequest, isSavingNotificationSettingInProgress} = this.state;

        const buttonOkDisabled = !newNotificationSettingRequest?.validates || isSavingNotificationSettingInProgress;

        const uniqueNodeLocations = new Array<string>();

        for (const key in dmsNodes) {
            const n = dmsNodes[key];

            if (uniqueNodeLocations.indexOf(n.location) === -1) {
                uniqueNodeLocations.push(n.location);
            }
        }

        const availableTGMStates = [...Object.keys(TGMState)].filter((value, index, arr) => {
            return arr.indexOf(value) === index;
        });

        const availableBGMStates = [...Object.keys(ABDState)].filter((value, index, arr) => {
            return arr.indexOf(value) === index;
        });

        return (<ModalDialog
                key={"new_notification_subscription"}
                open={newNotificationSettingRequest !== undefined}
                buttonOkIsLoading={isSavingNotificationSettingInProgress}
                buttonOkDisabled={buttonOkDisabled}
                buttonCancelDisabled={isSavingNotificationSettingInProgress}
                title={`New Notification Subscription`}
                buttonOkTitle={"Save"}
                message={""}
                onOk={() => {
                    if (newNotificationSettingRequest) {
                        this.onSaveNotificationSettingRequested(newNotificationSettingRequest);
                    }
                }} onCancel={() => {
                this.setState({newNotificationSettingRequest: undefined});
            }}>
                <Container>
                    <div>
                        <FormControl fullWidth={true}>
                            <InputLabel>Template</InputLabel>
                            <Select
                                variant={"standard"}
                                value={newNotificationSettingRequest?.template}
                                onChange={(event) => {
                                    if (!newNotificationSettingRequest?.subscription) {
                                        return;
                                    }

                                    let templateKey: string = "";
                                    if (event && event.target && event.target.value) {
                                        templateKey = event.target.value as string;
                                    }

                                    const template = this.notificationSubscriptionTemplates.get(templateKey);

                                    if (template) {
                                        this.setState({
                                            newNotificationSettingRequest: {
                                                template,
                                                ...newNotificationSettingRequest,
                                                subscription: {
                                                    ...newNotificationSettingRequest!.subscription!,
                                                    // @ts-ignore
                                                    state: template.state,
                                                    stateContainsFilter: template.stateContainsFilter
                                                }
                                            }
                                        }, () => {
                                            this.validateNewNotificationSettingRequest(this.state.newNotificationSettingRequest);
                                        });
                                    }
                                }}
                            >
                                {
                                    Array.from(this.notificationSubscriptionTemplates.keys()).map((k, i) => {
                                        return <MenuItem key={i} value={k}>{`${k}`}</MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>

                        <Autocomplete
                            fullWidth={true}
                            multiple
                            id="locations"
                            // @ts-ignore
                            options={uniqueNodeLocations}
                            getOptionLabel={(option) => option.toString()}
                            // @ts-ignore
                            defaultValue={newNotificationSettingRequest?.subscription?.locations}
                            onChange={(event, value) => {
                                this.setState({
                                    newNotificationSettingRequest: {
                                        ...newNotificationSettingRequest,
                                        subscription: {
                                            ...newNotificationSettingRequest!.subscription!,
                                            locations: [value[0]]
                                        }
                                    }
                                }, () => {
                                    this.validateNewNotificationSettingRequest(this.state.newNotificationSettingRequest);
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Locations"
                                    placeholder="Locations"
                                />
                            )}
                        />
                        <FormControl fullWidth={true}>
                            <InputLabel>Software</InputLabel>
                            <Select
                                variant={"standard"}
                                value={this.state.newNotificationSettingRequest?.software ?? null}
                                onChange={(event) => {
                                    if (event && event.target && event.target.value) {
                                    this.setState({
                                        newNotificationSettingRequest: {
                                            ...newNotificationSettingRequest,
                                            software: event.target.value as string
                                        }
                                    });
                                }}
                                }
                            >
                                <MenuItem value={"Tagomat"}>{"Tagomat"}</MenuItem>
                                <MenuItem value={"Bagomat"}>{"Bagomat"}</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth={true}>
                            <InputLabel
                                shrink={!!this.state.newNotificationSettingRequest?.subscription?.state}>State</InputLabel>
                            <Select
                                variant={"standard"}
                                value={this.state.newNotificationSettingRequest?.subscription?.state ?? null}
                                onChange={(event) => {
                                    let newValue = "";
                                    if (event && event.target && event.target.value) {
                                        newValue = event.target.value as string;
                                    }

                                    // @ts-ignore
                                    newNotificationSettingRequest.subscription!.state = newValue!;

                                    if (newValue) {
                                        this.setState(prevState => ({
                                            newNotificationSettingRequest
                                        }), () => this.validateNewNotificationSettingRequest(newNotificationSettingRequest));
                                    }
                                }}
                            >
                                {
                                    this.state.newNotificationSettingRequest?.software === "Tagomat"
                                    ? availableTGMStates.map(k => (
                                        <MenuItem key={k} value={k}>{k}</MenuItem>
                                    ))
                                    : this.state.newNotificationSettingRequest?.software === "Bagomat"
                                        ? availableBGMStates.map(k => (
                                            <MenuItem key={k} value={k}>{k}</MenuItem>
                                        ))
                                        : null
                                }
                            </Select>
                        </FormControl>
                        <TextField
                            variant={"standard"}
                            fullWidth
                            value={newNotificationSettingRequest?.subscription?.stateContainsFilter ?? ""}
                            onChange={(event) => {
                                let newValue = "";
                                if (event && event.target && event.target.value) {
                                    newValue = event.target.value;
                                }

                                this.setState(prevState => ({
                                    newNotificationSettingRequest: {
                                        ...newNotificationSettingRequest,
                                        subscription: {
                                            ...newNotificationSettingRequest!.subscription!,
                                            stateContainsFilter: newValue
                                        }
                                    }
                                }), () => this.validateNewNotificationSettingRequest(this.state.newNotificationSettingRequest));
                            }}

                            label={"State Filter"}/>
                        <FormGroup
                            style={{justifyContent: "center", alignItems: "center", flexDirection: "row", margin: 8}}>
                            <FormControlLabel
                                control={<Switch
                                    onChange={(event, checked) => {
                                        this.setState({
                                            newNotificationSettingRequest: {
                                                ...newNotificationSettingRequest,
                                                subscription: {
                                                    ...newNotificationSettingRequest!.subscription!,
                                                    push: checked
                                                }
                                            }
                                        }, () => {
                                            this.validateNewNotificationSettingRequest(this.state.newNotificationSettingRequest);
                                        });
                                    }}
                                    value={newNotificationSettingRequest?.subscription?.push}/>}
                                label="Push"/>
                            <FormControlLabel
                                control={<Switch
                                    onChange={(event, checked) => {
                                        this.setState({
                                            newNotificationSettingRequest: {
                                                ...newNotificationSettingRequest,
                                                subscription: {
                                                    ...newNotificationSettingRequest!.subscription!,
                                                    mail: checked
                                                }
                                            }
                                        }, () => {
                                            this.validateNewNotificationSettingRequest(this.state.newNotificationSettingRequest);
                                        });
                                    }}
                                    value={newNotificationSettingRequest?.subscription?.mail}/>}
                                label="Mail"/>
                        </FormGroup>
                    </div>
                </Container>
            </ModalDialog>
        );
    };

    private onBanIPRequested = async (req: IBanIPRequest) => {
        this.setState({
            isSavingBannedIpInProgress: true
        });

        try {
            const msg = DMSMessageFactory.newMessage(DMSMethod.BAN_IP, {ip: req.ip});
            const result = await this.props.dmsClient!.sendMessage<IDMSResult<void>>(msg, true);

            if (result?.error) {
                throw result.error;
            }
        } catch (e) {
            this.props.displaySnackbar("Failed to ban IP Error: " + JSON.stringify(e), "error");
        } finally {
            this.setState({
                isSavingBannedIpInProgress: false,
                banIPRequest: undefined
            });

            await this.props.onLoadGlobalSettingsRequested();
        }
    };

    private validateBanIPRequest = Util.debounce((req: IBanIPRequest) => {
            let isValid: boolean;

            if (!req) {
                isValid = false;
            } else {
                isValid = req.ip === "127.0.0.1" || (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i).test(req.ip ?? "");
            }

            this.setState(prevState => ({
                banIPRequest: {
                    ...prevState.banIPRequest,
                    validates: isValid
                }
            }));
        }
        , 500);

    private renderBanIPDialog = () => {
        const {banIPRequest, isSavingBannedIpInProgress} = this.state;

        return (<ModalDialog
            key={"ban_ip"}
            open={banIPRequest !== undefined}
            buttonOkIsLoading={isSavingBannedIpInProgress}
            buttonOkDisabled={!banIPRequest?.validates || isSavingBannedIpInProgress}
            buttonCancelDisabled={isSavingBannedIpInProgress}
            title={`Ban IP`}
            buttonOkTitle={"Ban"}
            message={""}
            onOk={() => {
                if (banIPRequest) {
                    this.onBanIPRequested(banIPRequest);
                }
            }} onCancel={() => {
            this.setState({banIPRequest: undefined});
        }}>
            <Container>
                <div>
                    <TextField
                        variant={"standard"}
                        value={banIPRequest?.ip ?? ""}
                        fullWidth
                        label={"IP"}
                        onChange={(event) => {
                            let newValue = "";
                            if (event && event.target && event.target.value) {
                                newValue = event.target.value;
                            }

                            this.setState(prevState => ({
                                banIPRequest: {
                                    ...prevState.banIPRequest,
                                    ip: newValue
                                }
                            }), () => this.validateBanIPRequest(banIPRequest));
                        }}
                        style={{margin: 8, minWidth: "120px"}}>
                    </TextField>
                </div>
            </Container>
        </ModalDialog>);
    };

    private onDeleteSoftwareRequested = async (software: IDMSSoftwareBundle) => {
        this.setState({
            isDeletingSoftwareInProgress: true
        });

        try {
            const msg = DMSMessageFactory.newMessage(DMSMethod.DELETE_DEFINED_SOFTWARE, {
                softwareName: software.name
            });
            const result = await this.props.dmsClient!.sendMessage<IDMSResult<void>>(msg, true);

            if (result?.error) {
                throw result.error;
            }
        } catch (e) {
            this.props.displaySnackbar("Failed to delete defined software Error: " + JSON.stringify(e), "error");
        } finally {
            this.setState({
                isDeletingSoftwareInProgress: false,
                deletionTargetSoftware: undefined
            });

            await this.props.onLoadSoftwareRequested();
        }
    };

    public render() {
        const {
            softwareTableColumns,
            bannedNodesColumns,
            bannedIpsColumns,
            notificationColumns,
            targetSoftware,
            targetNotificationSetting,
            targetBannedNode,
            targetBannedIp
        } = this.state;

        const bannedIps = this.props.globalSettings?.bannedIps ?? [];

        return ([
            this.renderDeleteSoftwareDialog(),
            this.renderNewSoftwareDialog(),
            this.renderNewNotificationSettingDialog(),
            this.renderBanIPDialog(),
            <Grid item xs={12} md={12} key={"personal_settings"}>
                <TableContainer component={Paper}>
                    <Card>
                        <CardContent>
                            <Typography variant={"h6"}>Personal Settings</Typography>
                            <FormControlLabel
                                control={<Switch
                                    checked={LocalStorageHelper.getUserOnlineNotificationsEnabled()}
                                    onChange={(ev) => {
                                        const checked = ev.target?.checked ?? false;
                                        LocalStorageHelper.setUserOnlineNotificationsEnabled(checked);
                                        this.forceUpdate();
                                    }}/>} label="User Online Notifications"/>
                            <FormControlLabel
                                control={<Switch
                                    checked={LocalStorageHelper.getPortForwardingNotificationsEnabled()}
                                    onChange={(ev) => {
                                        const checked = ev.target?.checked ?? false;
                                        LocalStorageHelper.setPortForwardingNotificationsEnabled(checked);
                                        this.forceUpdate();
                                    }}/>} label="Port Forwarding Notifications"/>
                        </CardContent>
                    </Card>
                </TableContainer>
            </Grid>,
            <Grid item xs={12} md={12} key={"notifications"}>
                <TableContainer component={Paper}>
                    <MaterialTable
                        title="Personal Notification Settings"
                        columns={notificationColumns}
                        isLoading={this.props.isLoadingNotificationSettings}
                        data={this.props.notificationSettings}
                        options={{
                            headerStyle: {
                                backgroundColor: "rgb(65,65,65)",
                            },
                            draggable: false,
                            pageSize: 25,
                            pageSizeOptions: [25, 50, 100],
                            emptyRowsWhenPaging: false,
                            padding: "dense",
                            search: false,
                            paging: false
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add',
                                isFreeAction: true,
                                onClick: () => {
                                    this.setState({
                                        newNotificationSettingRequest: {
                                            // @ts-ignore
                                            subscription: {
                                                mail: false,
                                                push: false
                                            }
                                        },
                                    });
                                }
                            },
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => {
                                    this.props.onLoadNotificationSettingsRequested();
                                }
                            },
                        ]}
                    />
                </TableContainer>
            </Grid>,
            <Grid item xs={12} md={6} key={"software"}>
                <TableContainer component={Paper}>
                    <MaterialTable
                        title="Software"
                        columns={softwareTableColumns}
                        isLoading={this.props.isLoadingDefinedSoftware}
                        data={this.props.definedSoftware}
                        options={{
                            headerStyle: {
                                backgroundColor: "rgb(65,65,65)",
                            },
                            draggable: false,
                            pageSize: 25,
                            pageSizeOptions: [25, 50, 100],
                            emptyRowsWhenPaging: false,
                            padding: "dense",
                            search: false,
                            paging: false
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add',
                                isFreeAction: true,
                                onClick: () => {
                                    this.setState({
                                        newSoftwareRequest: {
                                            updateStrategy: DMSUpdateStrategy.KILL_REPLACE_START
                                        },
                                    });
                                }
                            },
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => {
                                    this.props.onLoadSoftwareRequested();
                                }
                            },
                        ]}
                    />
                </TableContainer>
            </Grid>,
            <Grid item xs={12} md={6} key={"banned_nodes"}>
                <TableContainer component={Paper}>
                    <MaterialTable
                        title="Banned Nodes"
                        columns={bannedNodesColumns}
                        isLoading={this.props.isLoadingBannedNodes}
                        data={this.props.bannedNodes}
                        options={{
                            headerStyle: {
                                backgroundColor: "rgb(65,65,65)",
                            },
                            draggable: false,
                            pageSize: 25,
                            pageSizeOptions: [25, 50, 100],
                            emptyRowsWhenPaging: false,
                            padding: "dense",
                            search: false,
                            paging: false
                        }}
                        actions={[
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => {
                                    this.props.onLoadBannedNodesRequested();
                                }
                            },
                        ]}
                    />
                </TableContainer>
            </Grid>,
            <Grid item xs={12} md={6} key={"banned_ips"}>
                <TableContainer component={Paper}>
                    <MaterialTable
                        title="Banned IP Addresses"
                        columns={bannedIpsColumns}
                        isLoading={this.props.globalSettings === undefined}
                        data={bannedIps}
                        options={{
                            headerStyle: {
                                backgroundColor: "rgb(65,65,65)",
                            },
                            draggable: false,
                            pageSize: 25,
                            pageSizeOptions: [25, 50, 100],
                            emptyRowsWhenPaging: false,
                            padding: "dense",
                            search: false,
                            paging: false
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add',
                                isFreeAction: true,
                                onClick: () => {
                                    this.setState({
                                        banIPRequest: {},
                                    });
                                }
                            },
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => {
                                    this.props.onLoadGlobalSettingsRequested();
                                }
                            },
                        ]}
                    />
                </TableContainer>

                <Menu
                    open={this.state.notificationSettingMenuAnchorElement != null}
                    onClose={() => {
                        this.setState({notificationSettingMenuAnchorElement: undefined});
                    }}
                    id="menu-cell"
                    anchorEl={this.state.notificationSettingMenuAnchorElement}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <MenuItem
                        onClick={async () => {
                            try {
                                this.setState({
                                    isSavingNotificationSettingInProgress: true,
                                    notificationSettingMenuAnchorElement: undefined,
                                });

                                const jwtToken = LocalStorageHelper.getAuthToken();

                                if (jwtToken === null) {
                                    console.log("missing jwt token");
                                    return false;
                                }

                                const result = await this.props.dmsRestClient.deleteNotificationSubscription(jwtToken, (targetNotificationSetting)!.id!);

                                if (result) {
                                    this.props.displaySnackbar("Successfully deleted notification setting", "info");
                                }
                            } catch (e) {
                                this.props.displaySnackbar("Failed to delete notification subscription. Error: " + JSON.stringify(e), "error");
                            } finally {
                                this.setState({
                                    isSavingNotificationSettingInProgress: false
                                }, () => {
                                    this.props.onLoadNotificationSettingsRequested();
                                });
                            }
                        }}>Delete</MenuItem>
                    <MenuItem
                        onClick={async () => {
                            try {
                                this.setState({
                                    isSavingNotificationSettingInProgress: true,
                                    notificationSettingMenuAnchorElement: undefined,
                                });

                                const jwtToken = LocalStorageHelper.getAuthToken();

                                if (jwtToken === null) {
                                    console.log("missing jwt token");
                                    return false;
                                }

                                const result = await this.props.dmsRestClient.testNotificationSubscription(jwtToken, (targetNotificationSetting)!.id!);

                                if (result) {
                                    this.props.displaySnackbar("Successfully sent test notification", "info");
                                }
                            } catch (e) {
                                this.props.displaySnackbar("Failed to delete notification subscription. Error: " + JSON.stringify(e), "error");
                            } finally {
                                this.setState({
                                    isSavingNotificationSettingInProgress: false
                                });
                            }
                        }}>Test</MenuItem>
                </Menu>
                <Menu
                    open={this.state.softwareMenuAnchorElement != null}
                    onClose={() => {
                        this.setState({softwareMenuAnchorElement: undefined});
                    }}
                    id="menu-cell"
                    anchorEl={this.state.softwareMenuAnchorElement}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            let r: IDMSSoftwareBundle | undefined = targetSoftware;

                            this.setState({
                                softwareMenuAnchorElement: undefined,
                                deletionTargetSoftware: r
                            });
                        }}>Delete</MenuItem>
                </Menu>
                <Menu
                    open={this.state.bannedNodesMenuAnchorElement != null}
                    onClose={() => {
                        this.setState({
                            bannedNodesMenuAnchorElement: undefined,
                            targetBannedNode: undefined
                        });
                    }}
                    id="menu-cell"
                    anchorEl={this.state.bannedNodesMenuAnchorElement}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            let r: IDMSNodeBanState | undefined = targetBannedNode;

                            this.setState({
                                bannedNodesMenuAnchorElement: undefined,
                                targetBannedNode: undefined
                            });

                            if (r) {
                                this.unbanNode(r.uid);
                            }
                        }}>Unban</MenuItem>
                </Menu>
                <Menu
                    open={this.state.bannedIpsMenuAnchorElement != null}
                    onClose={() => {
                        this.setState({
                            bannedIpsMenuAnchorElement: undefined,
                            targetBannedIp: undefined
                        });
                    }}
                    id="menu-cell"
                    anchorEl={this.state.bannedIpsMenuAnchorElement}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            let r = targetBannedIp;

                            this.setState({
                                bannedIpsMenuAnchorElement: undefined,
                                targetBannedIp: undefined
                            });

                            this.unbanIp(r!);
                        }}>Unban</MenuItem>
                </Menu>
            </Grid>,
        ]);
    }
}
