import React, {Component} from "react";
import {SvgIcon} from "@mui/material";

interface IProps {
    size: 'inherit' | 'large' | 'medium' | 'small';
}

export default class SOIcon extends Component<IProps, any> {
    public render() {
        return <SvgIcon viewBox="0 0 150 150" fontSize={this.props.size}>
            <g className="layer">
                <title>Layer 1</title>
                <g fill="currentColor" id="svg_1" transform="translate(-8 219) scale(0.1 -0.1)">
                    <path
                        d="m110,1885c0,-11 54,-86 97,-136c29,-34 70,-67 103,-84c50,-25 69,-29 195,-35c186,-9 233,-29 278,-115c17,-33 21,-58 22,-140c0,-93 -2,-104 -29,-150c-16,-27 -31,-44 -33,-38c-3,7 0,21 6,32c43,79 -31,231 -111,231l-33,0l4,-47l3,-47l-32,27c-17,15 -35,25 -40,22c-5,-3 -16,-25 -25,-50c-17,-44 -17,-123 0,-170c4,-12 -5,-7 -23,15c-38,44 -52,90 -52,172c0,52 6,77 31,126c17,34 42,71 57,82l26,21l-70,-3l-69,-3l-24,-50c-13,-27 -28,-78 -34,-112c-23,-130 36,-272 141,-341c49,-33 63,-37 117,-37c54,0 68,4 117,37c58,38 115,114 139,183c8,25 13,84 13,160c-1,100 -5,128 -22,168c-44,98 -118,163 -227,200c-16,5 -88,13 -160,17c-121,6 -221,24 -322,56c-24,7 -43,11 -43,9z"
                        id="svg_2"/>
                    <path
                        d="m1465,1824c-45,-19 -91,-27 -182,-34c-145,-10 -194,-28 -245,-91c-49,-60 -54,-74 -67,-156c-21,-139 -18,-153 47,-220c70,-73 94,-106 108,-153c10,-33 13,-35 58,-38c92,-6 171,61 212,182c23,66 16,167 -16,231c-25,49 -26,50 -75,53c-47,3 -49,2 -29,-12c12,-8 31,-39 44,-68c32,-74 25,-177 -16,-242c-35,-56 -46,-59 -28,-8c19,55 17,91 -12,152c-30,65 -36,70 -94,70l-45,0l4,-46l3,-47l-31,22c-17,12 -31,21 -31,19c0,-2 -7,-21 -15,-43l-14,-40l-1,57c0,34 8,79 21,113c17,45 28,60 63,80c36,21 55,25 125,25c71,0 89,4 142,30c63,32 113,89 136,156c14,40 16,39 -62,8z"
                        id="svg_3"/>
                    <path
                        d="m920,1354c0,-24 -35,-135 -54,-171c-8,-16 -26,-39 -40,-52c-14,-14 -26,-32 -26,-42c0,-19 96,-165 140,-214c15,-16 31,-37 36,-47c26,-48 124,174 124,281c0,76 -50,153 -158,245c-22,19 -22,19 -22,0z"
                        id="svg_4"/>
                </g>
            </g>
        </SvgIcon>;
    }
}